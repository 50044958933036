import { SearchSnomedTerm, SearchTerm, Study } from "../../types/search"
import Tippy from "@tippyjs/react";
import {
    ArrowsPointingOutIcon,
    UserIcon,
    ClipboardDocumentIcon,
    PhotoIcon,
    DocumentMagnifyingGlassIcon
} from "@heroicons/react/24/outline"
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import SeriesTable from "./SeriesTable";
import classNames from "classnames";
import { expandModalityName, makeStudyReportReadable, whichGeoIsSource, whichTypeIsSource, whichStateIsSource } from "../../utils/data_formatting";
import { useAuthStore } from "../../store/authStore";
import { useUserStore } from "../../store/userStore";
import { useSearchStore } from "../../store/searchStore";
import { useSearchTypeStore } from "../../store/searchTypeStore";
import { useSnomedSearchStore } from "../../store/snomedSearchStore";
import {
    removeFromCohort,
    addToCohort
} from '../../utils/request_cohort';
import { getPatientExamsResults, getPatientStudies } from "../../utils/request_studies";
import { useModalStore } from "../../store/modalStore";
import { highlightAndTruncateReport, highlightReportWithoutTruncation } from "../../utils/highlight";
import { useFilterStore } from "../../store/filterStore";
import { usePatientStore } from "../../store/patientStore";
import { formatSearchQueryForLucene } from "../../utils/lucene_helpers";
import { usePatient1AdvancedFiltersStore } from "../../store/patient1AdvancedFiltersStore";
import { usePatient2AdvancedFiltersStore } from "../../store/patient2AdvancedFiltersStore";
import { useAdvancedFilterStore } from "../../store/advancedFiltersStore";
import VideoComponent from "./VideoComponent";
import moment from "moment";

type StudyCardProps = {
    studyMetadata: Study;
    highlight?: boolean;
    page: string;
    examsHighlightColor?: string;
}
type TooltipProps = {
    content: React.ReactNode;
    children: React.ReactNode;
};

export default function StudyCard({
    studyMetadata,
    highlight,
    page,
    examsHighlightColor
}: StudyCardProps) {

    const {
        row_id,
        institution,
        text,
        summary,
        study_metadata,
        series_metadata,
        study_count,
        accession_number,
        date_added,
        positive_entity_coordinates
    } = studyMetadata;
    const sortByPatientIDThenStudyDate = (studies: any[]) => {
        return studies.sort((a, b) => {
            if (a.study_metadata.patient_id > b.study_metadata.patient_id) return 1;
            if (a.study_metadata.patient_id < b.study_metadata.patient_id) return -1;

            if (Date.parse(a.study_metadata.study_date) > Date.parse(b.study_metadata.study_date)) return 1;
            if (Date.parse(a.study_metadata.study_date) < Date.parse(b.study_metadata.study_date)) return -1;

            return 0;
        })
    }

    const searchSummary = useSearchStore(state => state.searchRequest.search_summary);

    // Auth State
    const token = useAuthStore(state => state.token);

    // User State
    const selectedProject = useUserStore(state => state.selectedProject)
    const updateCohort = useUserStore(state => state.updateCohort)
    const projects = useUserStore(state => state.projects)
    const isProjectBelongsToUser = useUserStore(state => state.isProjectBelongsToUser)

    // Modal State
    const setShowPatientStudiesModal = useModalStore(state => state.setShowPatientStudiesModal);
    const setPatientStudies = useModalStore(state => state.setPatientStudies);
    const setShowExpandedThumbnailModal = useModalStore(state => state.setShowExpandedThumbnailModal);
    const setExpandedThumbnailModalSource = useModalStore(state => state.setExpandedThumbnailModalSource)
    const setExpandedThumbnailFiletype = useModalStore(state => state.setExpandedThumbnailFiletype)
    const setShowLoadingPatientStudiesModal = useModalStore(state => state.setShowLoadingPatientStudiesModal);
    const setHighlightRow = useModalStore(state => state.setHighlightRow);
    const patientExamsResults = useModalStore(state => state.patientExamsResults);
    const setPatientExamsResults = useModalStore(state => state.setPatientExamsResults);
    const patientStudies = useModalStore(state => state.patientStudies);
    const setSeriesHasThumbnail = useModalStore(state => state.setSeriesHasThumbnail);

    // Advanced filter state
    const advancedFilters = useAdvancedFilterStore((state) => state.advancedFilters);


    // Patient 1 Advanced filter state
    const advancedFiltersEnabled1 = usePatient1AdvancedFiltersStore((state) => state.advancedFiltersEnabled);
    const advancedFilters1 = usePatient1AdvancedFiltersStore((state) => state.advancedFilters);

    // Patient 2 Advanced filter state
    const advancedFiltersEnabled2 = usePatient2AdvancedFiltersStore((state) => state.advancedFiltersEnabled);
    const advancedFilters2 = usePatient2AdvancedFiltersStore((state) => state.advancedFilters);
    const timeBetween = usePatientStore((state) => state.timeBetween);

    // Filter State
    const usOnly = useFilterStore(state => state.usOnly);

    // Patient Sate
    const patientFilters = usePatientStore(state => state.filters);
    const searchType = useSearchTypeStore(state => state.searchType);

    // Local State
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [isIncludedInProject, setIsIncludedInProject] = useState<boolean>(false);
    const [isIncludedInPreviousProject, setIsIncludedInPreviousProject] = useState<boolean>(false);
    const [orderStatus, setOrderStatus] = useState<string>("OPEN");
    const [isToggling, setIsToggling] = useState<boolean>(false);
    const [videos, setVideos] = useState<any[]>([]);
    const [copied, setCopied] = useState<boolean>(false);
    const [selectVolumeOpen, setSelectVolumeOpen] = useState(false);

    //snomed search store
    const rawSearchTerms = useSnomedSearchStore(state => state.rawSearchTerms);

    useEffect(() => {
        if (selectedProject) {
            setOrderStatus(selectedProject?.cohort?.status);
            const isIncludedInProject = selectedProject?.cohort?.studies?.some(study => study?.row_id === row_id);
            setIsIncludedInProject(isIncludedInProject);
        }

        if (projects) {
            let listOfAllRows: any[] = []
            projects.forEach((project) => {
                project.cohort.studies.forEach((study) => {
                    listOfAllRows.push(study);
                });
            });
            const isIncludedInPreviousProject = listOfAllRows.some(study => study.row_id === row_id);
            setIsIncludedInPreviousProject(isIncludedInPreviousProject);
        }
    }, [selectedProject, selectedProject?.cohort, projects,row_id])

    useEffect(() => {
        // Get the first three non-null images
        let firstFiveSeries: string[] = [];
        for (var i = 0; i < series_metadata.length; i++) {
            if (series_metadata[i].thumbnail &&
                series_metadata[i].Modality !== 'SR' &&
                series_metadata[i].Modality !== 'AU' &&
                series_metadata[i].Modality !== 'PR') {
                firstFiveSeries.push(series_metadata[i].thumbnail);
            }
            if (firstFiveSeries.length === 5) {
                break;
            }
        }
        Promise.all(firstFiveSeries).then((firstFiveSeries) => {
            setVideos(firstFiveSeries);
        });

        const hasEmptyThumbnail = series_metadata.some(
            (series) => series.thumbnail === ""
        );
        setSeriesHasThumbnail(hasEmptyThumbnail);
    }, [series_metadata])

    const handleExpandAndCollapse = () => {
        setIsExpanded(!isExpanded);
    }

    const toggleStudy = async () => {
        setIsToggling(true);
        if (selectedProject) {
            if (isIncludedInProject) {
                const updatedProjects = await removeFromCohort(selectedProject.cohort.id, selectedProject.id, row_id, institution, selectedProject.email, token);
                updateCohort(updatedProjects);
            } else {
                const updatedProjects = await addToCohort(selectedProject.cohort.id, selectedProject.id, row_id, institution, selectedProject.email, token);
                updateCohort(updatedProjects);
            }
        }
        setIsToggling(false);
    }

    const handlePatientStudiesClick = () => {

        const getAllStudiesFromPatient = async () => {
            setHighlightRow(row_id);
            setShowLoadingPatientStudiesModal(true);
            const patientId = study_metadata.patient_id;
            let studies = await getPatientStudies(patientId, token);
            // Confirm that the study is from the same patient
            const patientAge = parseInt(study_metadata.parsed_patient_age);
            const studyDate = new Date(Date.parse(study_metadata.study_date));
            studies.results = studies.results.filter((study: any) => {
                const diff = new Date(Date.parse(study.study_metadata.study_date)).getUTCFullYear() - studyDate.getUTCFullYear();
                return (patientAge >= study.study_metadata.parsed_patient_age - diff - 1) && (patientAge <= study.study_metadata.parsed_patient_age - diff + 1)
            });
            setPatientStudies(sortByPatientIDThenStudyDate(studies.results));
            setShowLoadingPatientStudiesModal(false);
            setShowPatientStudiesModal(true);
        }
        getAllStudiesFromPatient();
        searchType === "patient" && getPatientAllExamsResults();
    }

    const getPatientAllExamsResults = async () => {
        let tempPatientSearchRequest: any;
        const patientId = study_metadata.patient_id;
        if (token) {
            let tempFilters = patientFilters;
            tempFilters[0].search_summary = "";
            tempFilters[1].search_summary = "";
            tempFilters[0].search_query = formatSearchQueryForLucene(
                tempFilters[0].filters.searchTerms
            );
            tempFilters[1].search_query = formatSearchQueryForLucene(
            tempFilters[1].filters.searchTerms
            );
            if (advancedFiltersEnabled1) {
                tempFilters[0].advancedFilters = advancedFilters1;
            }
            if (advancedFiltersEnabled2) {
                tempFilters[1].advancedFilters = advancedFilters2;
            }
            if (
            (advancedFilters && advancedFiltersEnabled1) ||
            (advancedFilters && advancedFiltersEnabled2)
            ) {
                tempPatientSearchRequest = {
                    filters: [...tempFilters],
                    advancedFilters,
                };
            } else {
                tempPatientSearchRequest = patientFilters;
            }

            tempPatientSearchRequest = {
                filters: [...tempFilters],
                timeBetween: timeBetween,
                patient_id: patientId,
            };
            try {
            const examsResults = await getPatientExamsResults(
                tempPatientSearchRequest,
                token
            );
            setPatientExamsResults(examsResults.data);
            } catch (error) {
                console.log(error);
            }
        }
    };
    useEffect(() => {
        const uniqueExam2RowIds = patientExamsResults.exam2_row_ids.filter(id => !patientExamsResults.exam1_row_ids.includes(id));
        const highlightExamsInStudies = patientStudies.map((study) => {
            let colorFlag = "";
            if (patientExamsResults.exam1_row_ids.includes(study.row_id)) {
                colorFlag = "purple";
            } else if (uniqueExam2RowIds.includes(study.row_id)) {
                colorFlag = "green";
            }
            return { ...study, examsHighlightColor: colorFlag };
        });
        const sortedHighlightExamsInStudies = highlightExamsInStudies.sort((a, b) => {
            const order:any = { "purple": 1, "green": 2 };
            return (order[a.examsHighlightColor] || 3) - (order[b.examsHighlightColor] || 3);
          });
        setPatientStudies(sortedHighlightExamsInStudies);
    }, [patientExamsResults]);

    const copyToClipboard = (content: string) => {
        navigator.clipboard.writeText(content).then(
            () => {
                setCopied(true);
                setTimeout(() => setCopied(false), 2000);
            },
            (err) => console.error('Could not copy text: ', err)
        );
    }

    const addDefaultImage = (ev: any) => {
        ev.target.src = "https://via.placeholder.com/100"
    }

    const handleShowExpandedThumbnailModal = (video: any, type: 'video' | 'image') => {
        setShowExpandedThumbnailModal(true);
        setExpandedThumbnailModalSource(video);
        setExpandedThumbnailFiletype(type);
    }

    const getFormattedReport = () => {
        if ((page === 'catalog' || page === 'patientStudiesModal') && ( 
            rawSearchTerms.length > 0 ||
            patientFilters[0].filters.searchTerms.length > 0 ||
            patientFilters[1].filters.searchTerms.length > 0
        )) {
            if (!isExpanded) {
                if (searchType == 'patient') {
                    return highlightAndTruncateReport(makeStudyReportReadable(text, institution), patientFilters[0].filters.searchTerms.concat(patientFilters[1].filters.searchTerms),positive_entity_coordinates)
                } else if (searchType == 'study') {
                    return highlightAndTruncateReport(makeStudyReportReadable(text, institution), rawSearchTerms,positive_entity_coordinates)
                }
            } else {
                if (searchType == 'patient') {
                    return highlightReportWithoutTruncation(makeStudyReportReadable(text, institution), patientFilters[0].filters.searchTerms.concat(patientFilters[1].filters.searchTerms),positive_entity_coordinates)
                } else if (searchType == 'study') {
                    return highlightReportWithoutTruncation(makeStudyReportReadable(text, institution), rawSearchTerms,positive_entity_coordinates)
                }
            }
        } else {
            return makeStudyReportReadable((searchSummary && summary) ? summary : text, institution)
        }
    }

    function formatDate(dateString: string) {
        const date = new Date(dateString);
        const month = date.getUTCMonth() + 1;
        const formattedDate = date.getUTCFullYear() + " " + month + " " + date.getUTCDate();
        return formattedDate;
    }

    const studyDateToDisplay = formatDate(study_metadata.study_date);
    const ingestionDateToDisplay = formatDate(date_added);

    function Tooltip({ content, children }: TooltipProps) {
        return (
            <Tippy content={content} arrow delay={500} interactive placement="top" maxWidth="none">
                <div>{children}</div>
            </Tippy>
        );
    }

    const handleDropDown = async (value: boolean) => {
        if (!isProjectBelongsToUser && !selectedProject?.is_editable_by_team) {
            setSelectVolumeOpen(value);
        }
    };

    let searchTermCount = 0;
    let termsToSearch: any;
 
    if (searchType === "study") {
        termsToSearch = rawSearchTerms;
    } else if (searchType === "patient") {
        termsToSearch = patientFilters[0].filters.searchTerms.concat(patientFilters[1].filters.searchTerms);
    }

    termsToSearch.forEach((term: any) => {
        let startIndex = 0;
        let textToLowerCase = text.toLowerCase()
        let searchIndex = textToLowerCase.indexOf(term.term.toLowerCase(), startIndex);
        while (searchIndex !== -1) {
        searchTermCount++;
        startIndex = searchIndex + term.term.length;
        searchIndex = textToLowerCase.indexOf(term.term.toLowerCase(), startIndex);
        }
    });

    const exportInitiatedToolTipContent = (
        <div className="w-80 z-50">
            <p>Unable to add a study to this project because the export process has already been initiated</p>
        </div>
        );
    return (
        <div className="py-2">
            <div className={`overflow-hidden rounded-lg bg-white border p-4 ${ searchType === 'patient' && examsHighlightColor ? `shadow-${examsHighlightColor}-700 shadow-xl` : highlight ? 'shadow-xl shadow-indigo-700' : null}`}>
                <div className="grid grid-cols-12">
                    <div className="col-span-8">
                    <div className="flex gap-x-4">                        
                            <div onMouseEnter={() => handleDropDown(true)} onMouseLeave={() => handleDropDown(false)}>
                                {
                                    orderStatus === "OPEN" ? (
                                            <button
                                                type="button"
                                                disabled={orderStatus !== "OPEN" || (!isProjectBelongsToUser && !selectedProject?.is_editable_by_team)}
                                                onClick={toggleStudy}
                                                className={
                                                    classNames(
                                                        "rounded bg-white px-2 py-1 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 disabled:bg-slate-400 disabled:text-gray-50 disabled:cursor-not-allowed",
                                                        {
                                                            "text-blue-600 bg-white hover:bg-gray-50": !isIncludedInProject,
                                                            "text-red-500 hover:bg-gray-50": isIncludedInProject || isIncludedInPreviousProject,
                                                        }
                                                    )
                                                }
                                            >
                                                {isToggling ? "Loading..." : (!isIncludedInProject ? (isIncludedInPreviousProject ? "Previously Selected" : "Add to Project") : "Remove from Project")}
                                            </button>
                                    ) : (
                                        <Tooltip
                                            content={
                                                isIncludedInProject
                                                    ? selectedProject?.project_name
                                                    : exportInitiatedToolTipContent
                                            }
                                        >
                                            <button
                                                type="button"
                                                className="rounded px-2 py-1 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 bg-slate-400 text-gray-50 cursor-default"

                                            >
                                                {isIncludedInProject
                                                    ? "Export in progress"
                                                    : "Add to Project"}
                                            </button>
                                        </Tooltip>
                                    )
                                }
                                <div
                                    id="dropdownHover"
                                    className={`absolute ${selectVolumeOpen ? "" : "hidden"} rounded-lg overflow-hidden bg-gray-800 text-white text-sm mt-1 whitespace-nowrap w-auto left-auto`}>
                                    <ul className="py-2 w-full text-sm text-gray-700 dark:text-gray-200 rounded-lg" aria-labelledby="dropdownHoverButton">
                                        <li className="text-white px-1">
                                            Action cannot be performed for a teammate.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* Truncate row_id */}
                            <div className="flex items-center space-x-2">
                                {study_metadata.patient_id ? <button onClick={() => copyToClipboard(study_metadata.patient_id)} title="Copy to Clipboard" className="flex text-xs items-center text-blue-500 hover:underline">
                                    <UserIcon className="h-3 w-3 mr-1" /> {study_metadata.patient_id.replace("GRDN", "")}
                                </button> : null}
                            </div>
                            <div className="flex items-center space-x-2">
                                <button onClick={() => copyToClipboard(accession_number)} title="Copy to Clipboard" className="flex text-xs items-center text-blue-500 hover:underline">
                                    <DocumentMagnifyingGlassIcon className="h-3 w-3 mr-1" /> {accession_number ? accession_number.replace("GRDN", "") : "None"}
                                </button>
                                {copied && <span className="text-xs text-gray-500">Copied!</span>}
                            </div>
                        </div>                       
                    </div>
                    <div className="col-span-4">
                        <div className="flex items-center justify-end gap-x-4 justify-items-end">
                            {
                                page === 'patientStudiesModal' ?
                                    null
                                    :
                                    <button
                                        onClick={handlePatientStudiesClick}
                                        className="flex items-center rounded bg-white px-2 py-1 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                    >
                                        <UserIcon className="h-4 w-4 mr-1" /> See All From Patient ({study_count})
                                    </button>
                            }
                        </div>
                    </div>
                    <div className="col-span-8 py-2">   
                        <div className={`flex flex-col justify-between ${!isExpanded ? "h-36" : "h-auto"}`}>                            <div>
                                    <h3 className="text-base font-medium text-gray-700">
                                        Clinical Report
                                    </h3>
                                    <p className={
                                        classNames(
                                            "text-xs italic text-gray-600 max-w-2xl",
                                            { "line-clamp-3": !isExpanded },
                                            { "whitespace-pre-wrap": isExpanded },
                                        )}>
                                        {getFormattedReport()}
                                    </p>
                                </div>            
                                <div>                                   
                                {searchTermCount > 0 &&
                                    <div className="flex items-center">
                                        <h6 key="search-term-count" className="text-end text-sm">  Keywords found: {searchTermCount}</h6>
                                    </div>
                                    }
                                </div>
                        </div>
                    </div>
                    
                    <div className="col-span-4 ml-2">
                        <p className="flex justify-start py-2 text-sm text-gray-600 font-medium">Labels</p>
                        <div className="flex flex-wrap justify-start">
                            {
                                series_metadata.map((metadata: any) => metadata.modality).filter((value, index, array) => array.indexOf(value) === index).map((modality: string, index: number) => {
                                    return (
                                        <div className="p-1" key={`${modality}-${index}`}>
                                            <span className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
                                                {expandModalityName(modality)}
                                            </span>
                                        </div>
                                    )
                                })
                            }
                            {series_metadata.filter((metadata: any) => { return metadata.modality == 'CT' && metadata.kvp }).length ? <div className="p-1">
                                <span className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
                                    KVP: {series_metadata.filter((metadata: any) => { return metadata.modality == 'CT' && metadata.kvp }).map((metadata: any) => metadata.kvp).filter((value, index, array) => array.indexOf(value) === index).join(", ")}
                                </span>
                            </div> : null}
                            {series_metadata.filter((metadata: any) => { return metadata.modality == 'MR' && metadata.magnetic_field_strength }).length ? <div className="p-1">
                                <span className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
                                    MFS: {series_metadata.filter((metadata: any) => { return metadata.modality == 'MR' && metadata.magnetic_field_strength }).map((metadata: any) => metadata.magnetic_field_strength).filter((value, index, array) => array.indexOf(value) === index).join(", ")}
                                </span>
                            </div> : null}
                            {institution !== null && 
                                <div className="p-1">
                                    <span className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
                                        {whichGeoIsSource(institution)}
                                    </span>
                                </div>
                            }
                            {institution !== null && !institution.includes('quelea') &&
                            <div className="p-1">
                                <span className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
                                    {whichStateIsSource(institution)}
                                </span>
                            </div>
                            }
                            {institution !== null &&  !institution.includes('quelea') &&
                            <div className="p-1">
                                <span className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
                                    {whichTypeIsSource(institution)}
                                </span>
                            </div>
                            }
                            {institution !== null &&
                                <div className="p-1">
                                    <span className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
                                        {institution.split('-')[0]}
                                    </span>
                                </div>
                            }
                            {study_metadata.patient_sex !== null &&
                                <div className="p-1">
                                    <span className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
                                        {(study_metadata.patient_sex === 'M' || study_metadata.patient_sex.toLowerCase() === "male") ? 'Male' : ((study_metadata.patient_sex === 'F' || study_metadata.patient_sex.toLowerCase() === "female") ? 'Female' : 'Other')}
                                    </span>
                                </div>
                            }
                            {study_metadata.parsed_patient_age !== null  && 
                                <div className="p-1">
                                    <span className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
                                        {`${Math.round(study_metadata.parsed_patient_age)} years old`}
                                    </span>
                                </div>
                            }
                            {study_metadata.study_date !== null &&
                                <div className="p-1 cursor-default">
                                    <Tooltip content="Study Date">
                                        <span className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
                                            {moment(studyDateToDisplay).format('ddd MMM DD YYYY')}
                                        </span>
                                    </Tooltip>
                                </div>
                            }
                            {
                                series_metadata.map((metadata: any) => metadata.manufacturer).filter((manufacturer) => manufacturer).filter((value, index, array) => array.indexOf(value) === index && (value !== "Unknown" && value !== "null")).map((manufacturer: string, index: number) => {
                                    return (
                                        <div className="p-1" key={`${manufacturer}-${index}`}>
                                            <span className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
                                                {manufacturer.split(" ")[0]}
                                            </span>
                                        </div>
                                    )
                                })
                            }
                            {date_added !== null && 
                                <div className="p-1 cursor-default">
                                    <Tooltip content="Ingestion Date">
                                        <span className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
                                        Ingested {moment(ingestionDateToDisplay).format('MMM YYYY')}
                                        </span>
                                    </Tooltip>
                                </div>
                            }
                        </div>
                    </div>
                    {videos.length > 0 ?
                    <>
                        {!isExpanded ?
                            <div className="col-span-10 border py-1 px-1 border-gray-200 bg-gray-50 rounded-sm mt-2 h-36">
                                <div className="flex items-center mb-2">
                                    <p className="text-sm text-gray-600 font-medium">Scan Previews</p>
                                    <div className="relative group">
                                        <InformationCircleIcon className="h-4 w-4 text-blue-500" />
                                        <div className="ml-2 w-44 text-center absolute left-1/2 transform px-2 translate-x -translate-y-full bg-black text-white text-xs rounded-md p-1 opacity-0 group-hover:opacity-100 transition">
                                            Hover to over preview play
                                        </div>
                                    </div>
                                </div>
                                <div className="flex gap-x-2">
                                    {
                                        videos.length > 0 ?
                                            videos.map((video, index) => {
                                                return (
                                                    video?.includes('.mp4') ? <div onClick={() => handleShowExpandedThumbnailModal(video, 'video')} className="hover:cursor-pointer" key={`${video}-${row_id}-${index}`}>
                                                        <VideoComponent key={`video-${row_id}-${index}`} video={video} />
                                                    </div> :
                                                        <div className="relative hover:cursor-pointer" onClick={() => handleShowExpandedThumbnailModal(video, 'image')}>
                                                            <img width="100" height="100" key={`video-${row_id}-${index}`} src={video} alt='placeholder' onError={addDefaultImage} />
                                                            <div className="absolute bottom-0 right-0 w-full h-full">
                                                                <PhotoIcon className="h-5 w-5 text-gray-300" />
                                                            </div>
                                                        </div>
                                                )
                                            })
                                            :
                                            <span className="text-sm italic ">No Previews Available</span>
                                    }
                                </div>
                            </div>
                            :
                            <div className="col-span-10">
                                <SeriesTable series_metadata={series_metadata} />
                            </div>
                        }
                        <div className="relative flex flex-col col-span-2 justify-end items-end">
                            <div className={
                                classNames(
                                    "absolute right-0 p-2",
                                    { "bottom-0": !isExpanded },
                                    { "top-0": isExpanded }
                                )}>
                                <button
                                    type="button"
                                    onClick={handleExpandAndCollapse}
                                    className="flex rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                >
                                    <ArrowsPointingOutIcon className="h-5 w-5 text-gray-500 mr-2" /> {isExpanded ? "Collapse" : "Expand"}
                                </button>
                            </div>
                        </div>
                    </>
                    :
                    <div className="col-span-12">
                        {!isExpanded ?
                            <div className="flex flex-row col-span-8 gap-1 items-center">
                                <InformationCircleIcon className="h-4 w-4 text-blue-500" />
                                <p className="text-sm text-gray-600 font-medium">Access to full study, images and metadata only on export.</p>
                            </div>
                            :
                            <div className="col-span-10">
                                <SeriesTable series_metadata={series_metadata} />
                            </div>
                        }
                        <div className="relative flex flex-col col-span-4 justify-end items-end">
                            <div className={
                                classNames(
                                    "absolute right-0 p-2",
                                    { "bottom-0": !isExpanded },
                                )}>
                                <button
                                    type="button"
                                    onClick={handleExpandAndCollapse}
                                    className="flex rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                >
                                    <ArrowsPointingOutIcon className="h-5 w-5 text-gray-500 mr-2" /> {isExpanded ? "Collapse" : "Expand"}
                                </button>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>
    )
}