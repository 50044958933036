import Search from "../search/search";
import SexFilter from "./sexFilter/sexFilter";
import AgeFilter from "./ageFilter/ageFilter";
import SliceThicknessFilter from "./SliceThicknessFilter/SliceThicknessFilter";
import AdvancedFiltersMain from "./advancedFilters/AdvancedFiltersMain";
// import { AdvancedFilterSearchRequest } from "../../types/advancedFilters";
// import { useFilterStore } from "../../store/filterStore";
import USOnlyFilter from "./US_Filter/USOnlyFilter";
import AiSummaryToggle from "./AISummaryToggle";
import ClearAllFiltersButton from "./ClearAllFiltersButton";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import Tippy from "@tippyjs/react";
import SaveSearchButton from "./SaveSearchButton";
import ManufacturerFilter from "./manufacturerFilter/ManufacturerFilter";
import ModalityFilter from "./modalityFilter/ModalityFilter";
import CrossFilter from "./crossFilter/CrossFilter";

export default function SearchByStudy() {

    // const setStudies = useSearchStore(state => state.setStudies);
    // const handleFiltersReset = useFilterStore(state => state.handleFiltersReset);

    // const handleReset = () => {
    //     // handleFiltersReset();
    //     // setStudies([]);
    //     // TODO: Short-term solution to reset filters
    //     window.location.reload();
    // }
    const tooltipContent = (
        <div className="w-80 z-50 flex flex-col gap-3 py-1">
            <p>You can search for multiple keywords in the medical report.</p>
            <p>If SNOMED search is enabled, an autocomplete list will show terms from the SNOMED CT tree, allowing you to select the ones you need.</p>
            <p>To perform a raw text search with SNOMED search enabled, use quotation marks. For example: "breast cancer" will return results for the exact keyword.</p>
        </div>
      );
    return (
        <div className="flex flex-col">
            <div className="flex flex-row items-center gap-1">
                <h3 className="text-lg font-medium text-gray-700">Filters</h3>
                    <Tippy
                        content={tooltipContent}
                        arrow={true}
                        delay={500}
                        interactive={true}
                        placement="top"
                        maxWidth="none"
                    >
                    <svg className="h-5 w-5 fill-slate-300" viewBox="0 0 20 20">
                    <InformationCircleIcon className="h-5 w-5 text-blue-600 cursor-pointer" />
                    </svg>
                    </Tippy>
                {/* <button 
                    className="text-sm hover:italic text-blue-500 hover:text-blue-600 underline"
                    onClick={handleReset}
                >
                    Reset Filters
                </button> */}
            </div>
            <div className="py-4">
                <Search />
            </div>
            <div className="flex flex-col divide-y divide-gray-300">
                <CrossFilter/>
                <USOnlyFilter />
                <SexFilter />
                <AgeFilter />
                <SliceThicknessFilter />
                <ModalityFilter />
                <ManufacturerFilter />
                <div className="divide-y divide-gray-300">                    
                <AdvancedFiltersMain />
                <ClearAllFiltersButton/>
                <SaveSearchButton />
                </div>
            </div>
        </div>
    )
}