import {useState } from "react";
import { useModalStore } from "../../../store/modalStore";
import { useUserStore } from "../../../store/userStore";
import WipeConfirmationModal from "./WipeModal";
import { useProjectsStore } from "../../../store/projectsStore";


export function WipeButton() {
    
    // Projects Store
    const downloadStatus = useUserStore(state => state.selectedProject)?.cohort.status;
    const isProjectBelongsToUser = useUserStore(state => state.isProjectBelongsToUser);
    const selectedProject = useUserStore(state => state.selectedProject);
    const studiesInProject = useProjectsStore(state => state.studiesInProject);
    //local State
    const [ selectVolumeOpen, setSelectVolumeOpen ] = useState(false);

    // Modal State
    const setShowWipeConfirmationModal = useModalStore(state => state.setShowWipeConfirmationModal);

    const handleWipe = () => {
        setShowWipeConfirmationModal(true);
    }
    const handleDropDown = async (value: boolean) => {
        if(!isProjectBelongsToUser && !selectedProject?.is_editable_by_team){
            setSelectVolumeOpen(value);
        }
      };

    return (
        <><div onMouseEnter={() => handleDropDown(true)} onMouseLeave={() => handleDropDown(false)}>
            {/* Button should not work if studies.length === 0 */}
            {downloadStatus == "OPEN" ? <button 
                onClick={handleWipe}
                disabled={downloadStatus !== "OPEN" || (!isProjectBelongsToUser && !selectedProject?.is_editable_by_team) || studiesInProject.length === 0}
                className="whitespace-nowrap rounded-lg bg-white px-3 py-2 text-sm font-semibold text-blue-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-300 disabled:text-gray-50 disabled:bg-gray-500 disabled:cursor-not-allowed">
                {downloadStatus === "OPEN" ? "Remove All" : downloadStatus === "INITIATED" ? "Export Initiated" : "Exported"}
            </button> : null}
            <WipeConfirmationModal />
        </div>
        <div
            id="dropdownHover"
            className={`absolute ${selectVolumeOpen ? "" : "hidden"} rounded-lg overflow-hidden bg-gray-800 text-white text-sm mt-12 whitespace-nowrap w-auto left-auto`}>
                <ul className="py-2 w-full text-sm text-gray-700 dark:text-gray-200 rounded-lg" aria-labelledby="dropdownHoverButton">
                    <li className="text-white px-1">
                    Action cannot be performed for a teammate.
                    </li>
                </ul>
            </div></>
    )
}