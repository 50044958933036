import { create } from "zustand";
import { SearchFilters, SearchSnomedTerm, SearchTerm, SnomedAutoCompleteList, SnomedDropDownRequest, SnomedInitialSearchRequest, SortObject } from "../types/search";
import { manufacturerSelectionsDefault } from "../components/filterControls/manufacturerFilter/manufacturerOptions";
import { modalityOptionsDefault } from "../components/filterControls/modalityFilter/modalityOptions";
const PAGESIZE = 10;

type SortArray = SortObject[];

interface SnomedSearchStore {
  autoCompleteData: SnomedAutoCompleteList[];
  setAutoCompleteData: (data: SnomedAutoCompleteList[]) => void;
  dropDownData: SearchSnomedTerm[];
  setDropDownData: (data: SearchSnomedTerm[]) => void;
  snomedSearchRequest: SnomedInitialSearchRequest;
  setSnomedSearchRequest: (searchRequest: SnomedInitialSearchRequest) => void;
  snomedSearchRequestPageOffset: number;
  setSnomedSearchRequestPageOffset: (snomedSearchRequestPageOffset: number) => void;
  updateSnomedSort: (searchSummary: SortArray) => void;
  isSnomedSearch: boolean;
  setIsSnomedSearch: (isSnomedSearch: boolean) => void;
  snomedbadgeCount: number;
  setSnomedBadgeCount: (count: number) => void;
  snomedDropDownData: SnomedDropDownRequest;
  setSnomedDropDownData: (snomedDropDownData: SnomedDropDownRequest) => void;
  SnomedDropDownDataArray: SnomedDropDownRequest[];
  setSnomedDropDownDataArray: (SnomedDropDownDataArray: SnomedDropDownRequest[]) => void;
  setSnomedDropDownDataForComponent: (tempData: SnomedDropDownRequest[], cui: number) => void;
  selectedPageSize: number;
  setSelectedPageSize: (numberOfStudies: number) => void;
  selectedCui: number;
  setSelectedCui: (selectedCui: number) => void;
  preloadSnomedCui: number;
  setPreloadSnomedCui: (preloadSnomedCui: number) => void;
  isSnomedSearchLoader: boolean;
  setIsSnomedSearchLoader: (isSnomedSearchLoader: boolean) => void;
  isSnomedSearchEnabled : boolean;
  setIsSnomedSearchEnabled : (isSnomedSearchLoader: boolean) => void;
  updateSnomedSearchRequestFilters: (filters: SearchFilters) => void;
  rawSearchTerms: SearchTerm[];
}

export const initialSnomedSearchrequest : SnomedInitialSearchRequest = {
  search_query: "",
  filters: {
    modality: modalityOptionsDefault.filter(item => item !== "null" && item !== "UNKNOWN"),
    us_only: false,
    cross_filter: false,
    age: {
      min: 0,
      max: 90
    },
    sex: ["M", "F", "O", "U"],
    manufacturer: manufacturerSelectionsDefault.filter(item => item !== "null" && item !== "UNKNOWN"),
    slice_thickness: {
      min: 0,
      max: 10
    },
  },
  searchTerms: [],
  pagination: {
    pageSize: PAGESIZE,
    offset: 0
  },
  sort: [
    {
      sort_field: "positive_entities",
      sort_method: "ASC"
    }
  ],
  is_snomed_search: false,
};

export const useSnomedSearchStore = create<SnomedSearchStore>((set) => ({
  autoCompleteData: [],
  setAutoCompleteData: (data) => set({ autoCompleteData: data }),
  dropDownData: [],
  setDropDownData: (data) => set({ dropDownData: data }),
  isSnomedSearch: false,
  setIsSnomedSearch: (isSnomedSearch: boolean) => set({ isSnomedSearch }),
  snomedSearchRequestPageOffset: 0,
  snomedSearchRequest: {
    search_query: "",
    filters: {
      modality: modalityOptionsDefault.filter(item => item !== "null" && item !== "UNKNOWN"),
      us_only: false,
      cross_filter: false,
      age: {
        min: 0,
        max: 90
      },
      sex: ["M", "F", "O", "U"],
      manufacturer: manufacturerSelectionsDefault.filter(item => item !== "null" && item !== "UNKNOWN"),
      slice_thickness: {
        min: 0,
        max: 10
      },
    },
    searchTerms: [],
    pagination: {
      pageSize: PAGESIZE,
      offset: 0
    },
    sort: [
      {
        sort_field: "positive_entities",
        sort_method: "ASC"
      }
    ],
    is_snomed_search: false,
  },
  setSnomedSearchRequest: (newSnomedSearchRequest: Partial<SnomedInitialSearchRequest>) =>
    set((state) => {
      const snomedSearchRequest = {
        ...state.snomedSearchRequest,
        ...newSnomedSearchRequest
      };
      const rawSearchTerms: SearchTerm[]  = []
      snomedSearchRequest.searchTerms.forEach(
        (searchTerm: SearchSnomedTerm) => {
          if (searchTerm.term !== "") {
              rawSearchTerms.push({
              operator: searchTerm.operator,
              term: searchTerm.term,
            });
          }
        }
      ); 
      return {
        ...state,
        snomedSearchRequest,
        rawSearchTerms
      }
    }),
  snomedDropDownData: {
    term: "",
    cui: 0,
    operator: "+",
    isChecked: false,
    children: []
  },
  setSnomedDropDownData: (newData: Partial<SnomedDropDownRequest>) =>
    set((state) => ({
      snomedDropDownData: {
        ...state.snomedDropDownData,
        ...newData
      }
    })),
  SnomedDropDownDataArray: [],
  setSnomedDropDownDataArray: (newData: SnomedDropDownRequest[]) =>
    set((state) => {
      return {
        ...state,
        SnomedDropDownDataArray: [...newData]
      };
    }),

  setSnomedDropDownDataForComponent: (newData: SnomedDropDownRequest[], cui: number) =>
    set((state) => {
      const updatedSnomedDropdownIndex = state.SnomedDropDownDataArray.findIndex((data) => data.cui === cui);
      let updatedDropdownData: SnomedDropDownRequest[] = [...state.SnomedDropDownDataArray];
      if (updatedSnomedDropdownIndex !== -1) {
        updatedDropdownData[updatedSnomedDropdownIndex] = newData[0];
      } else {
        updatedDropdownData.push(...newData)
      }
      return {
        ...state,
        SnomedDropDownDataArray: updatedDropdownData
      };
    }),

  setSnomedSearchRequestPageOffset: (snomedSearchRequestPageOffset: number) =>
    set((state) => {
      return {
        snomedSearchRequestPageOffset,
        snomedSearchRequest: {
          ...state.snomedSearchRequest,
          pagination: {
            ...state.snomedSearchRequest.pagination,
            offset: snomedSearchRequestPageOffset
          }
        }
      };
    }),
  updateSnomedSort: (sort: SortArray) =>
    set((state) => {
      let newSearchRequest: SnomedInitialSearchRequest = {
        ...state.snomedSearchRequest
      };
      if (sort.length > 0) {
        newSearchRequest.sort = sort;
      }else {
            delete newSearchRequest.sort
      }
      return {
        snomedSearchRequest: newSearchRequest
      };
    }),
  snomedbadgeCount: 0,
  setSnomedBadgeCount: (count) => set({ snomedbadgeCount: count }),
  selectedPageSize: PAGESIZE,
  setSelectedPageSize: (selectedPageSize: number) =>
    set((state) => {
      return {
        selectedPageSize,
        snomedSearchRequest: {
          ...state.snomedSearchRequest,
          pagination: {
            ...state.snomedSearchRequest.pagination,
            pageSize: selectedPageSize
          }
        }
      };
    }),
  selectedCui: -1,
  setSelectedCui: (selectedCui: number) =>
    set((state) => {
      return {
        selectedCui
      };
    }),
  preloadSnomedCui: -1,
  setPreloadSnomedCui: (preloadSnomedCui: number) =>
    set((state) => {
      return {
        preloadSnomedCui
      };
    }),
  isSnomedSearchLoader: true,
  setIsSnomedSearchLoader: (isSnomedSearchLoader: boolean) => set({ isSnomedSearchLoader }),
  isSnomedSearchEnabled :true,
  setIsSnomedSearchEnabled: (isSnomedSearchEnabled: boolean) => set({ isSnomedSearchEnabled }),
  updateSnomedSearchRequestFilters: (filters: SearchFilters) => set(state => {
    const { searchTerms, ...snomedSearchFilter } = filters;
    return {
      snomedSearchRequest: {
        ...state.snomedSearchRequest,
        filters: snomedSearchFilter
      }
    }
  }),
  rawSearchTerms: [],
}));
