import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useModalStore } from '../../../store/modalStore';
import { useUserStore } from '../../../store/userStore';
import { useAuthStore } from '../../../store/authStore';
import { createOrder } from '../../../utils/projects';
import { ToastMessage, useToastStore } from '../../../store/toastStore';

export default function OrderConfirmationModal() {
    // Modal State
    const open = useModalStore(state => state.showOrderConfirmationModal);
    const setOpen = useModalStore(state => state.setShowOrderConfirmationModal);
    const seriesHasThumbnail = useModalStore(state => state.seriesHasThumbnail);

    // User State
    const selectedProject = useUserStore(state => state.selectedProject);
    const setSelectedProjectById = useUserStore(state => state.setSelectedProjectById);
    const token = useAuthStore(state => state.token);
    const refreshUser = useUserStore(state => state.refreshUser);

    // Toast Store
    const setShowSuccessToast = useToastStore(state => state.setShowSuccessfulOrderToast);
    const setToasterCreationMessage = useToastStore(state => state.setToasterCreationMessage);
    const setShowCustomToast = useToastStore(state => state.setShowCustomToast);

    // Local State
    const [ isLoading, setIsLoading ] = useState(false);
    const [ requestFailed, setRequestFailed ] = useState(false);

    const handleConfirmOrder = async() => {
        setIsLoading(true);
        setRequestFailed(false); // Reset the failure state each time the button is clicked
        if (selectedProject) {
            try {
                const orderStatus = await createOrder(
                    selectedProject?.id,
                    selectedProject?.cohort.id,
                    token
                );
                await refreshUser(token);
                if (orderStatus.status === 'success') {
                    setShowSuccessToast(true);
                    setSelectedProjectById(selectedProject.id);
                    setOpen(false);
                }
                if (orderStatus?.warning) {
                    const warningMessage: ToastMessage = {
                        headerMessage: 'Warning',
                        bodyMessage: orderStatus?.warning,
                        type: 'warning',
                    };
                    setOpen(false);
                    setShowCustomToast(true);
                    setToasterCreationMessage(warningMessage)
                }
                 else {
                    setRequestFailed(true);
                }
    
            } catch (error) {
                setRequestFailed(true);
            } finally {
                setIsLoading(false);
            }
        }
    } 


  const cancelButtonRef = useRef(null)

    return (
        <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={setOpen}>
            <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                        <InformationCircleIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                            You are about to initiate an order
                        </Dialog.Title>
                        <div className="mt-2">
                        <p className="text-sm text-gray-600">
                        Once you initiate an order for the chosen project, we'll let you know as soon as it's ready for download. This might take up to 24 hours. If any hiccups occur, rest assured, a member from the Gradient Health team will be in touch.
                        </p>
                        <br/>
                        <div className="flex flex-col gap-6 mb-8">
                            <span className='font-semibold text-black' >Delivery estimation:</span>  
                            {seriesHasThumbnail ? 
                            <div className="flex flex-col gap-1">
                                <span className="text-2xl text-blue-600 font-bold bg-slate-100 px-4 py-1 rounded-md text-center">3 to 14 business days</span>
                                <p className="text-xs text-black-600 ">*This cohort includes studies that are only partially downloaded and need to be fully downloaded</p>
                            </div>
                            : 
                            <span className="text-2xl text-blue-600 font-bold bg-slate-100 px-4 py-1 rounded-md text-center">2 business days</span>
                        }
                        </div>
                        <p className="text-sm text-gray-600">
                            <span className='font-semibold text-black'>Please note:</span> While you can't add new studies to this project, you're free to add them to your other projects
                        </p>
                        </div>
                    </div>
                    </div>
                    <div className="mt-5 sm:ml-10 sm:mt-4 sm:flex sm:pl-4">
                    <button
                        disabled={isLoading}
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto"
                        onClick={handleConfirmOrder}
                    >
                    {!isLoading ? (
                            requestFailed ? "Request failed. Click to try again" : "Confirm Order"
                        ) : (
                            "Confirming..."
                        )}
                    </button>
                    <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                    >
                        Cancel
                    </button>
                    </div>
                </Dialog.Panel>
                </Transition.Child>
            </div>
            </div>
        </Dialog>
        </Transition.Root>
    )
}
