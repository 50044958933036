import React, { useEffect } from "react";
import { useProjectsStore } from "../../../store/projectsStore";

interface IsEditableByTeamProps {
  initiallyIsEditableByTeam: boolean;
  downloadStatus: string | undefined;
  isLoading: boolean;
}

export default function IsEditableByTeam({
  initiallyIsEditableByTeam,
  downloadStatus,
  isLoading,
}: IsEditableByTeamProps) {
  
  const isEditableByTeam = useProjectsStore(state => state.isEditableByTeam);
  const setIsEditableByTeam = useProjectsStore(state => state.setIsEditableByTeam);

  useEffect(() => {
    setIsEditableByTeam(initiallyIsEditableByTeam);
    return () => setIsEditableByTeam(false);
  }, []);

  return (
    <div className="mt-2 flex flex-row  max-w-xl text-sm text-gray-500">
      <input
        disabled={downloadStatus !== "OPEN" || isLoading}
        type="checkbox"
        className={`h-4 w-4 text-blue-600 focus:ring-blue-600 border-gray-300 rounded mt-1.5 ${
          downloadStatus !== "OPEN" || isLoading
            ? "cursor: default"
            : "cursor-pointer"
        }`}
        id="isEditable"
        checked={isEditableByTeam}
        onChange={(e) => setIsEditableByTeam(e.target.checked)}
      />
      <label
        className={`ml-2 mt-1 ${
          downloadStatus !== "OPEN" || isLoading
            ? "cursor: default"
            : "cursor-pointer"
        }`}
        htmlFor="isEditable"
      >
        Allow team members to edit this cohort
      </label>
    </div>
  );
}
