import { Fragment, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { useModalStore } from '../../store/modalStore'
import { updateProjectName } from '../../utils/projects'
import { useAuthStore } from '../../store/authStore'
import { useState } from 'react'
import { useUserStore } from '../../store/userStore'
import { useToastStore } from '../../store/toastStore'
import { useProjectsStore } from '../../store/projectsStore'
import { duplicateProjectWarningMessage, projectExportInitiatedMessage, projectExportedMessage, projectNameCompulsoryMessage, projectNameLengthExceedingMessage, successfulProjectRenameMessage, successfulProjectSettingsUpdatedMessage } from '../common/Constants'
import IsEditableByTeam from './isEditableByTeam/IsEditableByTeam'

interface WarningMessage{
    show:boolean;
    warningMessage:string;
}

export default function EditProjectNameModal() {

    // Modal State
    const showEditProjectNameModal = useModalStore(state => state.showEditProjectNameModal);
    const setShowEditProjectNameModal = useModalStore(state => state.setShowEditProjectNameModal);
    const setShowDeleteProjectModal=useModalStore(state=>state.setShowDeleteProjectModal);

    // User Store
    const refreshUser = useUserStore(state => state.refreshUser);
    const setSelectedProjectById = useUserStore(state => state.setSelectedProjectById);
    const projects=useUserStore(state => state.projects);

    // Auth State
    const token = useAuthStore(state => state.token);

    // Toast Store
    const setShowCustomToast = useToastStore(state => state.setShowCustomToast);
    const setToasterCreationMessage = useToastStore(state => state.setToasterCreationMessage);

    //project store
    const selectedProjectForEditName = useProjectsStore(state => state.selectedProjectForEditName);
    const isEditableByTeam = useProjectsStore(state => state.isEditableByTeam);
    const downloadStatus = useUserStore(state => state.selectedProject)?.cohort.status;

    // Local State
    const [isLoading, setIsLoading] = useState(false);
    const [projectName, setProjectName] = useState<string>('');
    const [warningMessage, setWarningMessage] = useState<WarningMessage>({ show: false, warningMessage: '' });


    const handleCreateNewProjectName = async (e: any) => {
        if (e) e.preventDefault();
        const changedProjectName = projectName.trim();
        if (changedProjectName !== '' && changedProjectName.length <= 80) {
            const existingProject = projects.find((project: any) => project.project_name.toLowerCase() === changedProjectName.toLowerCase());
            if (!(isEditableByTeam !== selectedProjectForEditName.isEditableByTeam || !existingProject)) {
                if (existingProject) {
                    setWarningMessage(duplicateProjectWarningMessage);
                } 
            } else {
                setIsLoading(true);
                try {
                    if (token) {
                        await updateProjectName(token, selectedProjectForEditName.id, { project_name: changedProjectName, is_editable_by_team: isEditableByTeam });
                        await refreshUser(token);
                        setSelectedProjectById(selectedProjectForEditName.id);
                    }

                } catch (error) {
                    console.error('Error editing project:', error);
                }
                setShowEditProjectNameModal(false);
                setShowCustomToast(true);
                setProjectName('');
                setIsLoading(false);
                isEditableByTeam !== selectedProjectForEditName.isEditableByTeam ? setToasterCreationMessage(successfulProjectSettingsUpdatedMessage) : setToasterCreationMessage(successfulProjectRenameMessage);
            }
        } else if (changedProjectName.length > 80) {
            setWarningMessage(projectNameLengthExceedingMessage);
        }
        else {
            setWarningMessage(projectNameCompulsoryMessage);
        }
    }

    useEffect(() => {
        if(warningMessage===projectExportInitiatedMessage || warningMessage===projectExportedMessage){
           return
        }
        if (warningMessage.show) {
            const timeout = setTimeout(() => {
                setWarningMessage({ show: false, warningMessage: '' });
            }, 2000);
            return () => clearTimeout(timeout);
        }
    }, [warningMessage])

    useEffect(() => {
        setProjectName(selectedProjectForEditName.project_name);
    }, [selectedProjectForEditName])

    useEffect(() => {
        if (downloadStatus === 'INITIATED') {
            setWarningMessage(projectExportInitiatedMessage);
        }
        else if (downloadStatus === 'CLOSED') {
            setWarningMessage(projectExportedMessage);
        }
        return()=>setWarningMessage({ show: false, warningMessage: '' });
    }, [downloadStatus]);
    const handleForOpenDelete=() => {
        setShowDeleteProjectModal(true);
        setShowEditProjectNameModal(false);
    };

    return (
        <Transition.Root show={showEditProjectNameModal} as={Fragment}>
            <Dialog as="div" className="relative z-100" onClose={setShowEditProjectNameModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-2 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                        onClick={() => setShowEditProjectNameModal(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className="px-4 py-5 sm:p-6">
                                <h3 className="text-base font-semibold leading-6 text-gray-900">Edit Project</h3>
                                    <form className="mt-5 sm:flex sm:items-center" onSubmit={handleCreateNewProjectName}>
                                        <div className="w-full sm:max-w-xs">
                                            <input
                                                type="text"
                                                disabled={downloadStatus !== "OPEN" || isLoading}
                                                name="projectName"
                                                id="projectName"
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 disabled:text-gray-500 disabled:bg-gray-100 disabled:border-gray-200 disabled:shadow-none"
                                                value={projectName}
                                                onChange={(e) => setProjectName(e.target.value)}
                                            />
                                        </div>
                                        <button
                                            disabled={downloadStatus !== "OPEN"}
                                            type='button'
                                            onClick={handleCreateNewProjectName}
                                            className={`mt-3 inline-flex w-full items-center justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:ml-3 sm:mt-0 sm:w-auto  disabled:text-gray-50 disabled:bg-gray-500 disabled:cursor-not-allowed ${isLoading ? 'bg-blue-600 px-3 py-2 text-sm font-semibold text-white pointer-events-none cursor-not-allowed':''}`}
                                        >
                                            {!isLoading ?
                                                "Update" :
                                                "Updating..."
                                            }
                                        </button>
                                    </form>
                                    <IsEditableByTeam initiallyIsEditableByTeam={selectedProjectForEditName.isEditableByTeam} downloadStatus={downloadStatus} isLoading={isLoading} />
                                </div>
                                <div className="pointer-events-none flex items-center">
                                    {warningMessage?.show && (<><ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                        <p className="text-sm text-red-600 ml-2" id="email-error">
                                            {warningMessage?.warningMessage}
                                        </p></>)}
                                </div>
                                <div className="flex items-center px-4">
                                    <p className='ml-2'>
                                    <a href="#" className="text-gray-600 underline text-sm" onClick={handleForOpenDelete}>Click here to delete project</a>
                                    </p>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}