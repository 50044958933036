import { useEffect, useRef, useState } from "react";
import CustomModal from "../modals/CustomModal";
import CreateNewSearch from "../modals/modalContents/CreateNewSearch";
import { usePatientStore } from "../../store/patientStore";
import { useSearchTypeStore } from "../../store/searchTypeStore";
import { useAdvancedFilterStore } from "../../store/advancedFiltersStore";
import { AdvancedFilterSearchRequest } from "../../types/advancedFilters";
import { useSavedSearchStore } from "../../store/savedSeachStore";
import { usePatient2AdvancedFiltersStore } from "../../store/patient2AdvancedFiltersStore";
import { usePatient1AdvancedFiltersStore } from "../../store/patient1AdvancedFiltersStore";
import { initialSnomedSearchrequest, useSnomedSearchStore } from "../../store/snomedSearchStore";
import { SnomedInitialSearchRequest } from "../../types/search";
import { nullCheckingInFilters } from "../../utils/null_checking_filters";

export default function SaveSearchButton() {
    //local state
    const [showNewSearchModal, setShowNewSearchModal] = useState(false);
    const isSearchChanged = useSavedSearchStore(state => state.isSearchChanged);
    const setIsSearchChanged = useSavedSearchStore(state => state.setIsSearchChanged);

    const searchType = useSearchTypeStore((state) => state.searchType);
    const snomedSearchRequest = useSnomedSearchStore((state) => state.snomedSearchRequest);
    const patientFilters = usePatientStore((state) => state.filters);
    const advancedFilters = useAdvancedFilterStore(state => state.advancedFilters);

    const advancedFilters1 = usePatient1AdvancedFiltersStore(state => state.advancedFilters);
    const advancedFilters2 = usePatient2AdvancedFiltersStore(state => state.advancedFilters);

    const prevAdvancedFiltersRef = useRef<AdvancedFilterSearchRequest | null>(advancedFilters);    
    const prevAdvancedFilters1Ref = useRef<AdvancedFilterSearchRequest | null>(advancedFilters1);
    const prevAdvancedFilters2Ref = useRef<AdvancedFilterSearchRequest | null>(advancedFilters2);

    const prevSnomedSearchRequestRef = useRef<SnomedInitialSearchRequest>(snomedSearchRequest);
    const prevPatientFiltersRef = useRef<any[]>(patientFilters);

    const [initialSnomed, setInitialSnomed] = useState<any>(initialSnomedSearchrequest);
    useEffect(() => {
        let patientFiltersChanged = false;
        if (searchType === "study") {
        const filtersChangedExcludingSnomedPagination = (
          JSON.stringify(prevSnomedSearchRequestRef.current) !== JSON.stringify(snomedSearchRequest) ||
          JSON.stringify(prevAdvancedFiltersRef.current) !==
          JSON.stringify(advancedFilters)
      )
        setIsSearchChanged(filtersChangedExcludingSnomedPagination)
        setInitialSnomed(snomedSearchRequest);
        prevSnomedSearchRequestRef.current = snomedSearchRequest
        } else {
        let initialPatient:any = patientFilters;
        if(initialPatient[0].filters && initialPatient[1].filters)
            initialPatient = [nullCheckingInFilters(initialPatient[0]), nullCheckingInFilters(initialPatient[1])];
        const hasPatientFiltersChanged = (
            JSON.stringify(Object.keys(initialPatient)) !== JSON.stringify(Object.keys(prevPatientFiltersRef.current)) ||
            JSON.stringify(initialPatient[0].filters) !== JSON.stringify(prevPatientFiltersRef.current[0].filters) ||
            JSON.stringify(initialPatient[1].filters) !== JSON.stringify(prevPatientFiltersRef.current[1].filters) ||
            JSON.stringify(prevAdvancedFilters1Ref.current) !== JSON.stringify(advancedFilters1) ||
            JSON.stringify(prevAdvancedFilters2Ref.current) !== JSON.stringify(advancedFilters2)           
        )
        patientFiltersChanged = hasPatientFiltersChanged;
    
        setIsSearchChanged(patientFiltersChanged);
        }
    }, [snomedSearchRequest,patientFilters, searchType, advancedFilters, advancedFilters1, advancedFilters2]);
    const handleClick = () => {
        setShowNewSearchModal(true);
    };
    useEffect(() => {
      let initialSearch = {
        ...initialSnomed,
      };
      if (snomedSearchRequest.project_id)
        initialSearch = {
          ...initialSearch,
          project_id: snomedSearchRequest.project_id,
        };
      initialSearch = nullCheckingInFilters({
        ...initialSearch,
      });

      const hasSearchRequestChanged =
        JSON.stringify(initialSearch) !==
          JSON.stringify(prevSnomedSearchRequestRef.current) ||
        JSON.stringify(prevAdvancedFiltersRef.current) !==
          JSON.stringify(advancedFilters);
      setIsSearchChanged(hasSearchRequestChanged);
    }, [snomedSearchRequest]);

    return (
        <>
            <div className="flex justify-center pt-3">
                <button
                onClick={handleClick}
                type="button"
                disabled={!isSearchChanged}
                className={`w-10/12 rounded ${isSearchChanged ? 'bg-blue-600 hover:bg-blue-500 focus-visible:outline-blue-600' : 'bg-gray-500'} px-4 py-1 text-sm font-semibold text-white shadow-md focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:bg-gray-500 disabled:cursor-not-allowed `}>
                Save Search
                </button>
            </div>
            {showNewSearchModal && <CustomModal showCustomModal={showNewSearchModal} setCustomModal={setShowNewSearchModal} >
                <CreateNewSearch setShowNewSearchModal={setShowNewSearchModal} />
            </CustomModal>}
        </>
    );
}
