import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useModalStore } from '../../store/modalStore'
import StudyCard from '../catalogDisplay/StudyCard';
import { addToCohort, bulkAddToCohort } from '../../utils/request_cohort';
import { useUserStore } from '../../store/userStore';
import { useAuthStore } from '../../store/authStore';
import classNames from "classnames";

export default function PatientStudiesModal() {

    // Modal Store
    const patientStudies = useModalStore(state => state.patientStudies);
    const setShowPatientStudiesModal = useModalStore(state => state.setShowPatientStudiesModal);
    const showPatientStudiesModal = useModalStore(state => state.showPatientStudiesModal);
    const highlightRow = useModalStore(state => state.highlightRow);
    const showExpandedThumbnailModal = useModalStore(state => state.showExpandedThumbnailModal);

    // User State
    const selectedProject = useUserStore(state => state.selectedProject);
    const updateCohort = useUserStore(state => state.updateCohort);
    const isProjectBelongsToUser = useUserStore(state => state.isProjectBelongsToUser);

    // Auth State
    const token = useAuthStore(state => state.token);

    // Local State
    const [ addingToProject, setAddingToProject ] = useState(false);
    const [ selectVolumeOpen, setSelectVolumeOpen ] = useState(false);
    const [ exportInProgressToastShow, setExportInProgressToastShow ] = useState(false);
    const [ isAllPatientAddedToProject, setIsAllPatientAddedToProject ] = useState(false);
    const PatientStudiesmodalRef = useRef<HTMLDivElement>(null);

    const handleClosed = () => {
        setShowPatientStudiesModal(false);
    }

    const handleAddAllToProject = async () => {
        setAddingToProject(true);
        if(selectedProject) {
            let allAdds = patientStudies.map((study) => {
              return {
                institution: study.institution,
                row_id: study.row_id,
              };
            });
            let alreadyAccepted = selectedProject.cohort.studies.map(
              (study) => study.row_id
            );
            allAdds = allAdds.filter((add) => {
              return !alreadyAccepted.includes(add.row_id);
            });
            const updatedProjects = await bulkAddToCohort(
              selectedProject.cohort.id,
              selectedProject.id,
              allAdds,
              selectedProject.email,
              token
            );
            updateCohort(updatedProjects);
            setIsAllPatientAddedToProject(true);
        }
        setAddingToProject(false);
    }

    useEffect(() => {
        function handleMouseDown(event: MouseEvent) {
            if (PatientStudiesmodalRef.current && !PatientStudiesmodalRef.current.contains(event.target as Node)) {
                if (!showExpandedThumbnailModal) {
                    handleClosed();
                }
            }
            event.stopPropagation();
        }
        document.addEventListener("mousedown", handleMouseDown);

        return () => {
            document.removeEventListener("mousedown", handleMouseDown);
        }
    }, [PatientStudiesmodalRef, showExpandedThumbnailModal])

    useEffect(() => {
      console.log(patientStudies);
      if (selectedProject) {
        let allAdds = patientStudies.map((study) => {
          return {
            institution: study.institution,
            row_id: study.row_id,
          };
        });
        let alreadyAccepted = selectedProject?.cohort?.studies?.map(
          (study) => study.row_id
        );
        allAdds = allAdds.filter((add) => {
          return !alreadyAccepted.includes(add.row_id);
        });
        console.log(allAdds);
        allAdds.length === 0 ? setIsAllPatientAddedToProject(true) : setIsAllPatientAddedToProject(false);
      }
    }, [patientStudies]);

    const handleDropDown = async (value: boolean) => {
        if(!isProjectBelongsToUser && !selectedProject?.is_editable_by_team){
            setSelectVolumeOpen(value);
        }
        else if(selectedProject?.cohort.status !== "OPEN") {
            setExportInProgressToastShow(value);
        }
    };

    return (
        <Transition.Root show={showPatientStudiesModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={()=>null}>
            <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                <Dialog.Panel  ref={PatientStudiesmodalRef} className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all my-20 sm:w-full sm:max-w-5xl sm:p-6">
                    <div className='flex flex-col'>
                        <div className='flex justify-between'>
                            <div>
                                <h2 className='text-xl font-medium'>
                                    Patient Studies
                                </h2>
                            </div>
                            <div className='flex justify-end'>
                                <div onMouseEnter={() => handleDropDown(true)} onMouseLeave={() => handleDropDown(false)}>
                                <button
                                    onClick={handleAddAllToProject}
                                    className={
                                        classNames(
                                            "bg-blue-600 hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mr-4 rounded-md  px-6 py-1.5 text-sm font-semibold text-white shadow-sm",
                                            {
                                                "text-white-600 bg-gray-500 cursor-not-allowed hover:bg-gray-400": (selectedProject?.cohort.status !== "OPEN" || !isProjectBelongsToUser && !selectedProject?.is_editable_by_team || isAllPatientAddedToProject),
                                            })}
                                    
                                    disabled={selectedProject?.cohort.status !== "OPEN" || addingToProject || (!isProjectBelongsToUser && !selectedProject?.is_editable_by_team) || isAllPatientAddedToProject}
                                >
                                    {
                                        addingToProject ?
                                        'Adding to Project...'
                                        :
                                        'Add All to Project'
                                    }
                                </button>
                                                <div
                                                    id="dropdownHover"
                                                    className={`absolute ${selectVolumeOpen ? "" : "hidden"} rounded-lg overflow-hidden bg-gray-800 text-white text-sm mt-1 whitespace-nowrap w-auto left-auto right-1`}>
                                                    <ul className="py-2 w-full text-sm text-gray-700 dark:text-gray-200 rounded-lg" aria-labelledby="dropdownHoverButton">
                                                        <li className="text-white px-1">
                                                            Action cannot be performed for a teammate.
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div
                                                    id="dropdownHover"
                                                    className={`absolute ${exportInProgressToastShow ? "" : "hidden"} ml-5 rounded-lg overflow-hidden bg-gray-800 text-white text-sm mt-1 whitespace-nowrap w-auto`}>
                                                    <ul className="py-2 w-full text-sm text-gray-700 dark:text-gray-200 rounded-lg" aria-labelledby="dropdownHoverButton">
                                                        <li className="text-white px-1">
                                                            Export in progress
                                                        </li>
                                                    </ul>
                                                </div>
                                </div>
                                <button
                                    type="button"
                                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    onClick={() => setShowPatientStudiesModal(false)}
                                >
                                    <span className="sr-only">Close</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                        </div>
                        </div>
                        {
                            patientStudies.length > 0 &&
                                patientStudies.map((study: any, index: number) => (
                                    <StudyCard examsHighlightColor={study.examsHighlightColor} highlight={study.row_id==highlightRow} studyMetadata={study} key={`${study.study_metadata.AccessionNumber}-${index}`} page="patientStudiesModal" />
                                ))
                        }
                        <div className='flex mt-2 justify-end'>
                            <button 
                                onClick={() => setShowPatientStudiesModal(false)} 
                                className="rounded-md w-1/6 bg-slate-700 px-2.5 py-1.5 text-sm font-semibold text-slate-100 shadow-sm ring-1 ring-inset ring-slate-300 hover:bg-slate-500"
                                >
                                Close
                            </button>
                        </div>
                        
                    </div>
                    
                </Dialog.Panel>
                </Transition.Child>
            </div>
            </div>
        </Dialog>
        </Transition.Root>
    )
    }
