import { SearchTerm } from "../types/search";
 
interface PositiveEntityCoordinates {
  text: string;
  start: number;
  stop: number;
}
interface highlights  {
  start: number; 
  end: number; 
  className: string 
}

// Helper function to get highlights for search terms
function getSearchTermHighlights(
  text: string,
  searchTerms: SearchTerm[]
):highlights[] {
  const highlights:highlights[] = [];
  
  searchTerms.forEach((term) => {
    let startIndex = 0;
    let textToLowerCase = text.toLowerCase();
    let searchIndex = textToLowerCase.indexOf(term.term.toLowerCase(), startIndex);
    while (searchIndex !== -1) {
      highlights.push({ start: searchIndex, end: searchIndex + term.term.length, className: 'bg-orange-400' });
      startIndex = searchIndex + term.term.length;
      searchIndex = textToLowerCase.indexOf(term.term.toLowerCase(), startIndex);
    }
  });
  
  return highlights;
}
 
// Helper function to get highlights for positive entity coordinates
function getPositiveEntityHighlights(
  text: string,
  positiveEntities: PositiveEntityCoordinates[],
  searchTerms: SearchTerm[]
): highlights[] {
  const highlights: highlights[] = [];
  
  positiveEntities.forEach((entity) => {
    const entityText = text.substring(entity.start, entity.stop).toLowerCase();
    searchTerms.forEach((term) => {
      if (entityText === term.term.toLowerCase()) {
        highlights.push({ start: entity.start, end: entity.stop, className: 'bg-green-400' });
      }
    });
  });
  
  return highlights;
}
 
// Merging highlights (green over orange)
function mergeHighlights(highlights: highlights[]): highlights[] {
  const merged: highlights[] = [];
  
  highlights.forEach((highlight) => {
    if (merged.length === 0 || highlight.start > merged[merged.length - 1].end) {
      merged.push(highlight);
    } else {
      const last = merged[merged.length - 1];
      last.end = Math.max(last.end, highlight.end);
      if (highlight.className === 'bg-green-400') {
        last.className = highlight.className;
      }
    }
  });
  
  return merged;
}
 
// Creating segments for rendering based on highlights
function createSegments(report: string, highlights: highlights[]): React.ReactElement[] {
  const segments: React.ReactElement[] = [];
  let lastIndex = 0;
  
  highlights.forEach((highlight) => {
    if (highlight.start > lastIndex) {
      segments.push(<span key={`plain-${lastIndex}`}>{report.substring(lastIndex, highlight.start)}</span>);
    }
    segments.push(
      <span key={`highlight-${highlight.start}`} className={highlight.className}>
        {report.substring(highlight.start, highlight.end)}
      </span>
    );
    lastIndex = highlight.end;
  });
  
if (lastIndex < report.length) {
    segments.push(<span key="last-segment">{report.substring(lastIndex)}</span>);
}
    return segments;
}

export function highlightReportWithoutTruncation(
  report: string,
  searchTerms: SearchTerm[],
  positive_entity_coordinates: PositiveEntityCoordinates[]
): React.ReactElement[] {
  const searchTermHighlights = getSearchTermHighlights(report, searchTerms);
  const positiveEntityHighlights = getPositiveEntityHighlights(report, positive_entity_coordinates, searchTerms);
  
  const highlights = [...searchTermHighlights, ...positiveEntityHighlights];
  
  // Sort and merge highlights
  highlights.sort((a, b) => a.start - b.start);
  const mergedHighlights = mergeHighlights(highlights);
  
  // Create segments
  return createSegments(report, mergedHighlights);
}
 
export function highlightAndTruncateReport(
  report: string,
  searchTerms: SearchTerm[],
  positive_entity_coordinates: PositiveEntityCoordinates[]
): React.ReactElement[] {
  const padding = 100;
  const MIN_LENGTH = 150;
  const segments: React.ReactElement[] = [];
  let lastEndIndex = 0;
  
  const searchTermHighlights = getSearchTermHighlights(report, searchTerms);
  const positiveEntityHighlights = getPositiveEntityHighlights(report, positive_entity_coordinates, searchTerms);
  
  const highlights = [...searchTermHighlights, ...positiveEntityHighlights];
  
  // Sort and merge highlights
  highlights.sort((a, b) => a.start - b.start);
  const mergedHighlights = mergeHighlights(highlights);
 
  mergedHighlights.forEach((highlight) => {
    const { start, end, className } = highlight;
    let startIndex = Math.max(0, start - padding);
    let endIndex = Math.min(report.length, end + padding);
    const currentLength = endIndex - startIndex;

    if (currentLength < MIN_LENGTH) {
      const additionalPadding = MIN_LENGTH - currentLength;
      endIndex += additionalPadding;
      if (endIndex > report.length) {
        startIndex -= (endIndex - report.length);
        endIndex = report.length;
      }
    }

    if (startIndex > lastEndIndex && lastEndIndex !== 0) {
      segments.push(<span key={`ellipsis-before-${start}`}>... </span>);
    }

    if (startIndex < start) {
      segments.push(<span key={`before-${start}`}>{report.substring(startIndex, start)}</span>);
    }
    segments.push(
      <span key={`highlight-${start}`} className={className}>
        {report.substring(start, end)}
      </span>
    );

    if (end < endIndex) {
    segments.push(<span key={`after-${end}`}>{report.substring(end, endIndex)}</span>);
    }

    lastEndIndex = endIndex;
  });
 
  if (lastEndIndex < report.length) {
    segments.push(<span key="last-segment-ellipsis"> ...</span>);
  }

    // Check if the only element in the segment array is "last-segment-ellipsis"
    if (segments.length === 1 && segments[0].key === "last-segment-ellipsis") {
        return [<strong key="did-not-find" className="text-gray-800">Keywords were not found in this report.</strong>, <span> {report} </span>];
    }
    
    segments.unshift(
        <span key="opening-quote">"</span>
    )

    segments.push(
        <span key="closing-quote">"</span>
    )

    return segments;
}
