import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useRef, useState } from "react";
import { useFilterStore } from "../../store/filterStore";
import { ParentItem, SearchSnomedTerm, SearchTerm, SnomedAutoCompleteList, SnomedDropDownRequest, SnomedInitialSearchRequest, SnomedTerm, SubChild } from "../../types/search";
import classNames from "classnames";
import { usePatientStore } from '../../store/patientStore'
import { useFilterDropDownStore } from "../../store/filterDropDownStore";
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css'; // optional for styling
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { Listbox, Transition } from "@headlessui/react";
import { getSnomedSearchDropDownLists, getSnomedSearchSuggestions } from "../../utils/request_studies";
import { debounce } from "../../utils/debounce";
import { useAuthStore } from "../../store/authStore";
import axios, { CancelTokenSource } from "axios";
import { useSnomedSearchStore } from "../../store/snomedSearchStore";
import { useSearchTypeStore } from "../../store/searchTypeStore";
import { formatSearchQueryForLucene } from "../../utils/lucene_helpers";

type SearchBarProps = {
    id?: number;
    searchOperator: '+' | '-' | 'OR';
    setSearchOperator: React.Dispatch<React.SetStateAction<'+' | '-' | 'OR'>>;
}

export default function SearchBar({id,searchOperator,setSearchOperator}: SearchBarProps) {
    // Filter State
    const searchTerms = useFilterStore(state => state.searchTerms);
    const setSearchTerms = useFilterStore(state => state.setSearchTerms);
    const filtersDisabled = useFilterStore(state => state.filtersDisabled);
    const setPatientSearchTerms = usePatientStore(state => state.setSearchTerms);
    const searchFilters = useFilterStore(state => state.filters);
    const patientFilters = usePatientStore(state => state.filters);
    const clearButtonClicked = useFilterDropDownStore(state => state.clearButtonClicked);
    const searchType = useSearchTypeStore(state => state.searchType);

    // Snomed Search store
    const autoCompleteData =useSnomedSearchStore (state => state.autoCompleteData);
    const setAutoCompleteData =useSnomedSearchStore (state => state.setAutoCompleteData);
    const snomedSearchRequest = useSnomedSearchStore(state => state.snomedSearchRequest);
    const setSnomedSearchRequest = useSnomedSearchStore(state => state.setSnomedSearchRequest);
    const setSnomedDropDownDataArray = useSnomedSearchStore(state => state.setSnomedDropDownDataArray); 
    const SnomedDropDownDataArray = useSnomedSearchStore(state => state.SnomedDropDownDataArray); 
    const setSnomedDropDownData = useSnomedSearchStore(state => state.setSnomedDropDownData); 
    const selectedCui = useSnomedSearchStore(state => state.selectedCui); 
    const setSelectedCui = useSnomedSearchStore(state => state.setSelectedCui); 
    const preloadSnomedCui = useSnomedSearchStore(state => state.preloadSnomedCui); 
    const setPreloadSnomedCui = useSnomedSearchStore(state => state.setPreloadSnomedCui); 
    const setIsSnomedSearchLoader = useSnomedSearchStore(state => state.setIsSnomedSearchLoader); 
    const isSnomedSearchEnabled = useSnomedSearchStore(state => state.isSnomedSearchEnabled);
    const setIsSnomedSearchEnabled = useSnomedSearchStore(state => state.setIsSnomedSearchEnabled);


    // Auth state
    const token = useAuthStore(state => state.token);

    const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const axiosSnomedSearchSource = useRef<CancelTokenSource | null>(null);
    const axiosSnomedDropDown = useRef<CancelTokenSource | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>('');

    

    useEffect(() => {
        setSearchTerm('');
        setSearchOperator('+');
    }, [clearButtonClicked]);

    const handleSuggestionList = (suggestion: string, cui: number, searchOperator :  '+' | '-'| 'OR') => {    
      setSelectedCui(cui)
      const updatedDropDownData: SnomedDropDownRequest = {
          term: suggestion,
          cui: cui,
          operator: searchOperator,
          isChecked: true,
          children: [],
      };
      if(SnomedDropDownDataArray?.length){
          setSnomedDropDownDataArray([...SnomedDropDownDataArray, updatedDropDownData])
      }
      else{
          setSnomedDropDownDataArray([updatedDropDownData])
      }
      setSearchTerm('');
      setAutoCompleteData([]);
  };   

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      let tempSnomedSearchRequest = { ...snomedSearchRequest };
      const newTerm: SearchTerm = {
        term: searchTerm.replace(/["']/g, ""),
        operator: searchOperator,
      };

      if (id != null) {
        setPatientSearchTerms(
          [...patientFilters[id].filters.searchTerms, newTerm],
          id
        );
      } else {
        const matchedSnomedItem: SnomedAutoCompleteList | undefined =
          autoCompleteData.find(
            (item: SnomedAutoCompleteList) =>
              item.snomed_tag_text === searchTerm
          );
        if (matchedSnomedItem && isSnomedSearchEnabled) {
          handleSuggestionList(searchTerm.replace(/["']/g, ""),matchedSnomedItem?.snomed_tag_cui,searchOperator)
          let newSearchTerm: SearchSnomedTerm = {
            operator: searchOperator,
            parent_snomed_tag: {
              cui: matchedSnomedItem?.snomed_tag_cui,
              term: searchTerm.replace(/["']/g, ""),
            },
            snomed_tags: [
              {
                cui: matchedSnomedItem?.snomed_tag_cui,
                term: searchTerm.replace(/["']/g, ""),
              },
            ],
            term: "",
          };
          tempSnomedSearchRequest.searchTerms = [
            ...tempSnomedSearchRequest.searchTerms,
            newSearchTerm,
          ];
          tempSnomedSearchRequest.is_snomed_search = true;
        } else {
          let newSearchTerm: SearchSnomedTerm = {
            operator: searchOperator,
            parent_snomed_tag: {
              cui: 0,
              term: "",
            },
            snomed_tags: [],
            term: searchTerm.replace(/["']/g, ""),
          };
          tempSnomedSearchRequest.searchTerms = [
            ...tempSnomedSearchRequest.searchTerms,
            newSearchTerm,
          ];
        }
        let allSearchTerms: {
          term: string;
          operator: "+" | "-" | "OR";
        }[] = [];
        tempSnomedSearchRequest.searchTerms.flatMap(
          (searchTerm: SearchSnomedTerm) => {
            if (searchTerm.snomed_tags[0]?.term) {
              searchTerm.snomed_tags.forEach((snomedTag, index) => {
                allSearchTerms.push({
                  term: snomedTag.term,
                  operator: index === 0 ? searchTerm.operator : ("OR" as const),
                });
              });
            } else {
              allSearchTerms.push({
                term: searchTerm.term,
                operator: searchTerm.operator,
              });
            }
          }
        );
        const searchQuery = formatSearchQueryForLucene(allSearchTerms);
        tempSnomedSearchRequest.search_query = searchQuery;
        setSnomedSearchRequest({
          ...snomedSearchRequest,
          is_snomed_search: tempSnomedSearchRequest.is_snomed_search,
          search_query: searchQuery,
          searchTerms: tempSnomedSearchRequest.searchTerms,
        });
      }
      setSearchTerm("");
    };
    
    const tooltipContent = (
        <div className="w-80 z-50">
            <p>You can search for multiple key words in the medical report</p>
        </div>
      );
    const snomedSmartIcon = (
    <div className="w-40 z-50">
        {searchType == "study" && !isSnomedSearchEnabled ?<p>Turn on SNOMED Search</p> : <p>Turn off SNOMED Search</p>}                        
    </div>
    );
    const fetchSnomedList = async () => {
        if (!token) {
            return
        }
        if (axiosSnomedSearchSource.current) {
            axiosSnomedSearchSource.current.cancel('Canceling previous request');
        }
        axiosSnomedSearchSource.current = axios.CancelToken.source();
        const isEnclosedInQuotes = searchTerm.startsWith('"') && searchTerm.endsWith('"') || searchTerm.startsWith('"') || searchTerm.endsWith('"');
        try {
            setShowSuggestions(true);
            setIsLoading(true);
            if (searchTerm?.length > 2 && !isEnclosedInQuotes) {
                const fetchData = await getSnomedSearchSuggestions(token, searchTerm, { cancelToken: axiosSnomedSearchSource.current.token });
                if (Array.isArray(fetchData)) {
                const matchedItem = fetchData?.filter((item) => {
                    return item?.snomed_tag_text?.toLowerCase() === searchTerm.toLowerCase();
                  });
                  
                  const unMatchedItem = fetchData?.filter((item) => {
                    return !matchedItem.includes(item);
                  })
                  
                  const sortedItems = [...matchedItem, ...unMatchedItem];
                  setAutoCompleteData(sortedItems);
                }
                setShowSuggestions(true);
                setIsLoading(true);
            } else {
                setShowSuggestions(false);
            }
            setIsLoading(false);
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled:', error.message);
            } else {
                console.error("Error fetching autoCompleteData:", error);
                setShowSuggestions(false);
            }
        }
    };

    const debouncedFetchSnomedList = debounce(fetchSnomedList, 300);


    useEffect(() => {
        // Debounced version of fetchSuggestions
      if (searchType === "study" && isSnomedSearchEnabled) {
        debouncedFetchSnomedList();
      }
    },[searchTerm])

    useEffect(() => {
        return () => {
            // Cleanup: cancel the request when the component unmounts
            if (axiosSnomedSearchSource.current) {
                axiosSnomedSearchSource.current.cancel('Canceling previous request on unmount');
            }
        };
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchTerm(value);
        if (!value) {
            setShowSuggestions(false);
        }
    };
    
    useEffect(() => {
        function handleMouseDown(event: MouseEvent) {
          if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setShowSuggestions(false);
          }
        }
        document.addEventListener("mousedown", handleMouseDown);
  
        return () => {
          document.removeEventListener("mousedown", handleMouseDown);
        }
      })

      const highlightMatchingText = (suggestion: string, searchTerm: string) => {
        const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const texts = suggestion.split(new RegExp(`(${escapedSearchTerm})`, 'gi'));
        return texts?.map((item, index) => 
            item.toLowerCase() === searchTerm.toLowerCase() ? (
                <span key={index} className="font-bold text-gray-900">{item}</span>
            ) : item
        );
    };


    const setIsCheckedRecursively = (data: ParentItem[]): SubChild[] => {
        return data.map((item) => ({
            ...item,
            isChecked: true,
            children: item.children ? setIsCheckedRecursively(item.children) : []
        }));    
    };

    const dropDownList = async (currentSelectedCui: number) => {
        axiosSnomedDropDown.current = axios.CancelToken.source();
        setIsSnomedSearchLoader(true);
        try {
            const fetchData = await getSnomedSearchDropDownLists(token, currentSelectedCui, {
                cancelToken: axiosSnomedDropDown.current.token
            });

            if (Array.isArray(fetchData)) {

                const tempMatchedItem = fetchData.find(item => item.cui === currentSelectedCui);

                let snomedData: SnomedDropDownRequest[] = SnomedDropDownDataArray.map((data) => {
                    if (data.cui === currentSelectedCui) {
                        return {
                            ...data,
                            children: tempMatchedItem
                                ? setIsCheckedRecursively(tempMatchedItem.children)
                                : data.children
                        };
                    } else {
                        return data;
                    }
                });

                setSnomedDropDownDataArray(snomedData);
                setIsSnomedSearchLoader(false);
            }
        } catch (error) {
            setIsSnomedSearchLoader(false);
            if (axios.isCancel(error)) {
                console.log("Request canceled:", error.message);
            } else {
                console.error("Error fetching autoCompleteData:", error);
            }
        }
    };

    const onSnomedIconToggle = () => {
      setIsSnomedSearchEnabled(!isSnomedSearchEnabled);
    };

    useEffect(() => {
        if (searchType === "study" && selectedCui !== -1) {
            dropDownList(selectedCui)
            setSelectedCui(-1);
        }
    }, [selectedCui]);

    useEffect(() => {
        if (searchType === "study" && preloadSnomedCui !== -1) {
            dropDownList(preloadSnomedCui)
            setPreloadSnomedCui(-1);
        }
    }, [preloadSnomedCui]);
    
        return (
                <form className="z-40" onSubmit={(e) => handleSubmit(e)}>
                    <div>       
                        <div className="w-full relative -mt-2 mb-2 rounded-md block sticky">   
                            <div className="absolute inset-y-0 left-0 flex items-center pl-2">
                                <Tippy
                                    content={searchType === "study" ? snomedSmartIcon : tooltipContent}
                                    arrow={true}
                                    delay={500}
                                    interactive={true}
                                    placement="top"
                                    maxWidth="none"
                                >
                                    {searchType === "study" ?(
                                    <a onClick={()=>onSnomedIconToggle()} className={`${searchType == "study" && isSnomedSearchEnabled ? "active  text-blue-500 border-blue-500" : "border-transparent"} flex items-center justify-center rounded-t-lg hover:text-blue-500 hover:border-blue-500 dark:hover:text-blue-500 group cursor-pointer`}>
                                        <svg className={`w-4 h-4 ${searchType == "study" && isSnomedSearchEnabled? "active text-blue-500" : "text-gray-400"}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 16.5A2.493 2.493 0 0 1 6.51 18H6.5a2.468 2.468 0 0 1-2.4-3.154 2.98 2.98 0 0 1-.85-5.274 2.468 2.468 0 0 1 .921-3.182 2.477 2.477 0 0 1 1.875-3.344 2.5 2.5 0 0 1 3.41-1.856A2.5 2.5 0 0 1 11 3.5m0 13v-13m0 13a2.492 2.492 0 0 0 4.49 1.5h.01a2.467 2.467 0 0 0 2.403-3.154 2.98 2.98 0 0 0 .847-5.274 2.468 2.468 0 0 0-.921-3.182 2.479 2.479 0 0 0-1.875-3.344A2.5 2.5 0 0 0 13.5 1 2.5 2.5 0 0 0 11 3.5m-8 5a2.5 2.5 0 0 1 3.48-2.3m-.28 8.551a3 3 0 0 1-2.953-5.185M19 8.5a2.5 2.5 0 0 0-3.481-2.3m.28 8.551a3 3 0 0 0 2.954-5.185"></path>
                                        </svg>
                                    </a>
                                ):(
                                    <svg className="h-4 w-4 fill-slate-300" viewBox="0 0 20 20">
                                        <InformationCircleIcon className="h-4 w-4 text-blue-600 cursor-pointer" />
                                    </svg>
                                )}
                                </Tippy>
                            {( id != null ? patientFilters[id].filters.searchTerms : searchTerms)  &&
                            <div className="h-full">                               
                                <label htmlFor="operator" className="sr-only">
                                    Operator
                                </label>
                                <select
                                    id="operator"
                                    name="operator"
                                    value={searchOperator}
                                    disabled={filtersDisabled}
                                    onChange={(e) => setSearchOperator(e.target.value as '+' | '-' | 'OR')}
                                    className="h-full rounded-md border-0 bg-transparent py-0 pr-[1.6rem] text-gray-500 font-semibold focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm disabled:cursor-not-allowed"
                                >
                                    <option value="+">AND</option>
                                    <option value="OR">OR</option>
                                    {(  id != null ? patientFilters[id].filters.searchTerms.length : snomedSearchRequest?.searchTerms?.length ) >= 1 && <option value="-">NOT</option>}
                                </select>
                            </div>
                            }
                            </div>                    
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </div>
                            <input
                                name="search"
                                id="search"
                                disabled={filtersDisabled}
                                className={classNames(
                                    "shadow-sm block w-full rounded-md border-0 py-1.5 pl-[5.7rem] text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 disabled:bg-gray-400 disabled:cursor-not-allowed",                            
                                )}
                                placeholder="Search Keywords"
                                value={searchTerm}
                                onChange={handleInputChange}
                                onFocus={() => setShowSuggestions(true)}
                                autoComplete={isLoading || autoCompleteData?.length > 0  ? "off" : "on"}
                            />
                                    {searchType === "study" && isSnomedSearchEnabled && searchTerm !== "" &&
                                    <div ref={dropdownRef}>
                                        <Listbox value={searchTerm}>
                                        <div className="relative">
                                            <Transition
                                                show={showSuggestions}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Listbox.Options                
                                                className={`absolute mt-1 w-full bg-white rounded-md shadow-lg max-h-60 overflow-auto focus:outline-none ${(isLoading && searchTerm !== "") ||(searchTerm !== "" && autoCompleteData?.length > 0)? "border border-gray-300": ""}`}>                                            {isLoading && searchTerm !== "" ? (
                                                    <Listbox.Option value="loading" disabled className="cursor-not-allowed select-none relative py-2 pl-10 pr-4 text-gray-900">
                                                    Loading...
                                                </Listbox.Option>
                                            ) : ( searchTerm !== "" && autoCompleteData?.map((item:SnomedAutoCompleteList) => (
                                                    <Listbox.Option 
                                                    onClick={() => handleSuggestionList(item.snomed_tag_text, item.snomed_tag_cui, searchOperator)}
                                                    key={item.snomed_tag_cui} 
                                                    value={item.snomed_tag_text}
                                                    className={({ selected }) =>`cursor-pointer select-none relative py-2 pl-10 pr-4 text-gray-900"} ${selected ? "font-medium" : "font-normal"}`}>                                                        
                                                        {highlightMatchingText(item.snomed_tag_text, searchTerm)}
                                                    </Listbox.Option>
                                                ))
                                                )
                                                }
                                            </Listbox.Options>
                                            </Transition>
                                        </div>
                                        </Listbox>
                                    </div>
                                    }
                        </div>
                    </div> 
                </form>
        );
    }