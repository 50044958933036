import { ToastMessage } from "../../store/toastStore"

export const successfulProjectCreationMessage: ToastMessage = {
    headerMessage: 'Project Created!',
    bodyMessage: 'You can now add studies to your new project.',
    type: 'success',
}
export const successfulProjectRenameMessage: ToastMessage = {
    headerMessage: "Project name changed successfully",
    type: 'success',
}
export const successfulProjectSettingsUpdatedMessage: ToastMessage = {
    headerMessage: "Settings changed successfully",
    type: 'success',
}
export const duplicateProjectWarningMessage = {
    show: true,
    warningMessage: "Project Name is already in use"
}
export const projectNameLengthExceedingMessage = {
    show: true,
    warningMessage: "Project name is too large. Maximum allowed is 80."
}
export const projectNameCompulsoryMessage = {
    show: true,
    warningMessage: "Project name cannot be empty."
}
export const projectExportInitiatedMessage = {
    show: true,
    warningMessage: "Export initiated project name can't be changed"
}
export const projectExportedMessage = {
    show: true,
    warningMessage: "Exported project name can't be changed"
}
export const successfulProjectDeletionMessage: ToastMessage = {
    headerMessage: "Project Deleted Successfully",
    type: 'success',

}
export const projectExportedWarningDeleteMessage = {
    show: true,
    warningMessage: "Exported project can't be deleted"
}
export const projectExportInitiatedWarningDeleteMessage = {
    show: true,
    warningMessage: "Export initiated project can't be deleted"
}
export const savedSearchNameAlreadyExistsMessage = {
    show: true,
    warningMessage: "Search name already exists"
}
export const savedSearchNameLengthExceedingMessage = {
    show: true,
    warningMessage: "Search name cannot be more than 80 characters"
}
export const uploadCSVRestrictionMessage: ToastMessage = {
    headerMessage: "Action cannot be performed for a teammate.",
    type: 'warning',
}