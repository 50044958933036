import { SearchTerm } from "../types/search";

export function formatSearchQueryForLucene(query: SearchTerm[]): string {
    if (query.length === 0) return '';
    const specialCharactersRegex = /[+!@#$'%^&*~(),.?":{}|<>]/;

    const formatTerm = (term: string): string => {
        const formattedTerm = term.replace(/\n/g, '\\n').trim();
            return formattedTerm.includes(' ') || 
            (formattedTerm.startsWith('(') && formattedTerm.endsWith(')')) || (formattedTerm.startsWith('(') ||  formattedTerm.endsWith(')')) ||
            specialCharactersRegex.test(formattedTerm) ? `"${formattedTerm}"` : formattedTerm;
    };


    if (query.length === 1) {
        return formatTerm(query[0].term);
    } else {
        const formattedQuery: string[] = query.map((searchTerm, index) =>  {
            if (index === 0) {
                return formatTerm(searchTerm.term);
            } else {
                return searchTerm.operator + ' ' + formatTerm(searchTerm.term);
            }
        });

        return formattedQuery.join(' ');
    }
}


export {}
