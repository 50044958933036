import { useEffect, useState } from "react"
import Chart from "apexcharts"
import { useChartsStore } from "../../../../../store/chartsStore";

export default function SexPieChart() {
    const [ sexChart, setSexChart ] = useState<Chart | null>(null);
    const cohortsChartsData = useChartsStore(state => state.cohortsChartsData);

    useEffect(() => {
         const getChartOptions = (labelsAndValues: any) => {
            const count = Object.keys(labelsAndValues).reduce(
                (accumulator, currentValue) => accumulator + labelsAndValues[currentValue], 0
            );
            return {
              series: Object.values(labelsAndValues),
              colors: ["#EC4899", "#3B82F6","#0000FF"],
              chart: {
                height: 200,
                width: "100%",
                type: "pie",
              },
              stroke: {
                colors: ["white"],
                lineCap: "",
              },
              plotOptions: {
                pie: {
                  labels: {
                    show: true,
                  },
                  size: "100%",
                  dataLabels: {
                    offset: -10
                  }
                },
              },
              labels: Object.keys(labelsAndValues),
              dataLabels: {
                enabled: true,
                style: {
                  fontFamily: "Inter, sans-serif",
                },
              },
              legend: {
                show: false
              },
              yaxis: {
                labels: {
                  formatter: function (value: any) {
                    return value
                  },
                },
              },
              xaxis: {
                labels: {
                  formatter: function (value: any) {
                    return value/count*100  + "%"
                  },
                },
                axisTicks: {
                  show: false,
                },
                axisBorder: {
                  show: false,
                },
              },
            }
          }

            if (cohortsChartsData && cohortsChartsData.patient_sex && Object.keys(cohortsChartsData.patient_sex).length > 0 &&
              document.getElementById("sex-pie-chart")
            ) {
              const { patient_sex } = cohortsChartsData;
              // Helper function to safely access values with case-insensitivity and provide default if undefined
              const getSexData = (sexKeys: string[]): number => 
                sexKeys.reduce((sum: number, key: string) => sum + (patient_sex[key as keyof typeof patient_sex] || 0), 0);
              const m = getSexData(["M", "Male", "male"]);
              const f = getSexData(["F", "Female", "female"]);
              const o = getSexData(["O", "Other", "other", "U", "Unknown", "unknown"]);

              if (!sexChart) {
                const chart = new Chart(document.getElementById("sex-pie-chart"), {
                  ...getChartOptions({ M: m, F: f, O: o }),
                });
                setSexChart(chart);
                chart.render();
              } else {
                sexChart.updateOptions({
                  ...getChartOptions({ M: m, F: f, O: o }),
                });
              }
            }


      }, [cohortsChartsData]);

      return (
        <>
            <div id="sex-pie-chart"/>
        </>
)
}