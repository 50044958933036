import { InformationCircleIcon } from "@heroicons/react/24/outline";
import Papa from "papaparse";
import { useUserStore } from "../../store/userStore";
import { useAuthStore } from "../../store/authStore";
import { bulkAddToCohort, requestStudiesCountInCohort } from "../../utils/request_cohort";
import { useState, useEffect } from "react";
import { LoadingSpinner } from "../../components/common/loadingSpinner";
import UploadCsvProgressBar from "./UploadCsvProgressBar";
import FileUpload from "./FileUpload";
import CsvUploadModal from "../../components/modals/csvUploadModal";
import { useProjectsStore } from "../../store/projectsStore";
import { useToastStore } from "../../store/toastStore";
import { uploadCSVRestrictionMessage } from "../../components/common/Constants";

const UploadCohortCSV: React.FC = () => {
  const token = useAuthStore((state) => state.token);
  const selectedProject = useUserStore((state) => state.selectedProject);
  const setProjects = useUserStore((state) => state.setProjects);
  const projects = useUserStore((state) => state.projects);
  const updateCohort = useUserStore((state) => state.updateCohort);
  const setStudiesCountInCohort = useProjectsStore((state) => state.setStudiesCountInCohort);
    
  // User State
  const selectedProjectOwnerEmail = useUserStore(state => state.selectedProjectOwnerEmail);
  const gradientUser = useUserStore(state => state.gradientUser);
  // toast Store
  const setShowCustomToast = useToastStore(state => state.setShowCustomToast);
  const setToasterCreationMessage = useToastStore(state => state.setToasterCreationMessage);

  const [isCsvUplaoding, setIsCsvUplaoding] = useState(false);
  const [totalElements, setTotalElements] = useState(0);
  const [addedElements, setAddedElements] = useState(0);
  const [modalMessage, setModalMessage] = useState<any>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadPermission, setUploadPermission] = useState(false);

  useEffect(() => {
    if (addedElements === totalElements) {
      // Added delay inorder to display 100% progress bar
      setTimeout(() => {
        setIsCsvUplaoding(false);
      }, 1000);
    }
  }, [addedElements]);

  useEffect(() => {
    const fetchCohortsCounts = async () => {
        if(selectedProject && selectedProjectOwnerEmail !== "") {
            const cohortCountResult = await requestStudiesCountInCohort(selectedProjectOwnerEmail,selectedProject.id, selectedProject.cohort.id, token)
            setStudiesCountInCohort(cohortCountResult?.count);       
        } 
    }
    fetchCohortsCounts();
}, [selectedProject, token]);

  useEffect(() => {
    if (selectedProjectOwnerEmail !== '' && gradientUser?.email !== '') {
      if (selectedProjectOwnerEmail === gradientUser?.email || selectedProject?.is_editable_by_team) {
        setUploadPermission(true);
      } else {
        setUploadPermission(false);
        setShowCustomToast(true);
        setToasterCreationMessage(uploadCSVRestrictionMessage);
      }
    }
  }, [selectedProjectOwnerEmail, gradientUser, selectedProject]);

  const handleFileDrop = (file: File) => {
    if (selectedProject?.cohort.status !== "OPEN") {
      setModalMessage(
        "Your export for this cohort has already been initiated."
      );
      setIsModalOpen(true);
      return;
    }
    const reader = new FileReader();
    reader.onload = async () => {
      if (!reader.result) return;

      const csvData: string = reader.result.toString();
      const { data: records } = Papa.parse<any>(csvData, {
        header: true,
        skipEmptyLines: true,
        dynamicTyping: false
      });

      const checkScientificNotation = records.some((record) =>
        Object.values(record).some((value) => typeof value === "string" && /^[+-]?\d+(\.\d*)?[eE][+-]?\d+$/.test(value))
      );
      if (checkScientificNotation) {
        console.log("CSV contains values in scientific notation. Please correct them and try again.");
        setModalMessage("CSV has formatted row_id's. Please correct it and try again.");
        setIsModalOpen(true);
        return;
      }

      const studies: any[] = [];
      const duplicates: string[] = [];
      const errors: number[] = [];

      if (records.length > 0) {
        if(selectedProject?.cohort.status==="OPEN"){
          let keywordForInstitution = Object.keys(records[0]).find(keyword => keyword.toLowerCase() === 'institution');
          let keywordForRowId = Object.keys(records[0]).find(keyword => keyword.toLowerCase() === 'row_id');
          if (keywordForRowId && keywordForInstitution) {
          setTotalElements(records.length - 1);
          for (let i = 0; i <= records.length - 1; i++) {
            if (selectedProject) {
              let recordRowID = records[i][keywordForRowId]?.trim();
              let recordInstitution = records[i][keywordForInstitution].trim();
              if (
                !recordRowID || !recordInstitution ||
                recordRowID === undefined ||
                recordInstitution === undefined
              ) {
                errors.push(i + 1);
              }
              if (recordRowID.endsWith("'")) {
                recordRowID = recordRowID.slice(0,-1);
              }
              if (recordRowID.startsWith("'")) {
                recordRowID = recordRowID.slice(1);
              }
              const rowId = recordRowID;
              const institution = recordInstitution;
              const studyIndex = selectedProject.cohort.studies.findIndex(
                (study) => study.row_id === rowId 
              );

                if (studyIndex === -1) {
                  if(rowId !=="" && institution !== "" && rowId !== undefined && institution !==undefined)
                    studies.push({ row_id: rowId, institution: institution });
                } else {
                  duplicates.push(`'${rowId}'`);
                }
              }
            }
            if (studies.length > 0) {
              setIsCsvUplaoding(true);
              await uploadStudy(studies);
            }
            const totalStudiesAdded = studies.length;
            const totalStudiesNotAddedDueToDuplicates = duplicates.length;
            const totalErrors = errors.length;
            setModalMessage(
            <div>
              <p>
                <span>Total studies added: </span> 
                <span id="total-studies-added">{totalStudiesAdded}</span>
              </p>
              <p className="mb-4">
                This is the number of unique studies successfully added to your project.
              </p>
              <p>
                <span>Studies not added due to duplicates: </span> 
                <span id="studies-not-added">
                  {totalStudiesNotAddedDueToDuplicates > 0 ? totalStudiesNotAddedDueToDuplicates : "0"}
                </span>
              </p>
              <p >
                {
                  totalStudiesNotAddedDueToDuplicates > 0
                    ? `We found ${totalStudiesNotAddedDueToDuplicates} study that was not added because it is already in your project.`
                    : ""
                }
              </p>
              <p className="mt-4">
                <span>Total errors: </span> 
                <span className="text-red-500">
                  {totalErrors > 0 ? totalErrors : ""}
                </span>
                {totalErrors > 0
              ? <div className="max-h-[150px] overflow-y-auto">
                  <span className="text-red-500">
                    {
                      records.length !== totalErrors
                        ? "Insufficient values at row" +
                          `${totalErrors > 1 ? "s\n" : " "}` +
                          errors
                        : ""
                    }
                  </span>
                </div>
              : "0"}  
              </p>
              <p>
                {
                  totalErrors > 0
                    ? ""
                    : "Great news! There were no errors during the upload process."
                }
              </p>
            </div>
            );
            setIsModalOpen(true);
          }else if(keywordForInstitution === undefined || keywordForRowId === undefined){
              console.log(
                "CSV does not have required columns 'row_id' and 'Institution'"
              );
              setModalMessage(
                "CSV does not have required columns 'row_id' and 'Institution'"
              );
              setIsModalOpen(true);
          }
        }else{
          console.log(
            "Studies cannot be added due to selected project cohort status is not 'OPEN'"
          );
          setModalMessage(
            "Studies cannot be added due to selected project cohort status is not 'OPEN'"
          );
          setIsModalOpen(true);         
        }
      }
    }

    reader.readAsText(file);
  };

  const uploadStudy = async (studies: any[]) => {
    try {
      if (token) {
        if (selectedProject) {
          let add = 0;
          const interval = setInterval(async () => {
            if (add < studies?.length - studies?.length / 10) {
              add += studies?.length / 10;
            }
            setAddedElements(add);
            if (add >= studies.length) {
              clearInterval(interval);
            }
          }, 100);
          const updatedProjects = await bulkAddToCohort(
            selectedProject.cohort.id,
            selectedProject.id,
            studies,
            selectedProject.email,
            token
          );
          setAddedElements(updatedProjects && studies.length);
          clearInterval(interval);
          updateCohort(updatedProjects);
      }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const closeModal = () => {
    setIsCsvUplaoding(false);
    setIsModalOpen(false);
  };
  return (
    <div className="flex flex-col h-screen">
      {isModalOpen && (
        <CsvUploadModal
          isOpen={isModalOpen}
          onClose={closeModal}
          message={modalMessage}
        />
      )}
      <div className="flex flex-row items-center my-10 justify-between">
        <div className="flex items-center">
          <h1 className="ml-4 text-3xl font-semibold text-gray-900 border-b-2 border-slate-300">
            Upload CSV
          </h1>
        </div>
      </div>
      <div className="flex justify-center">
        {isCsvUplaoding ? (
          <div className="hidden sm:flex flex-row items-center justify-end p-4">
            <p className="flex justify-start py-2 text-base text-gray-800 font-medium mr-4">
              Uploading CSV:
            </p>
            <div className="flex flex-col space-y-2">
              <span className="flex items-center">
                <UploadCsvProgressBar
                  totalElements={totalElements}
                  addedElements={addedElements}
                />
              </span>
            </div>
          </div>
        ) : null}
      </div>
      {uploadPermission && <div className="flex items-center ml-4">
        <InformationCircleIcon className="h-4 w-4 text-blue-600 mr-1" />
        <p className="text-gray-500 text-xs">
          You can drag and drop your CSV files here
        </p>
      </div>}
      <div className="px-4 mt-10">
        {isCsvUplaoding ? (
          <LoadingSpinner message="Uploading CSV in-progress...Wait for few minutes" />
        ) : 
        uploadPermission  && <FileUpload 
          onFileDrop={handleFileDrop}
           />
        }
      </div>
    </div>
  );
};
export default UploadCohortCSV;