import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useModalStore } from '../../../store/modalStore';
import { useUserStore } from '../../../store/userStore';
import { useAuthStore } from '../../../store/authStore';
import { useToastStore } from '../../../store/toastStore';
import { removeBulkStudiesFromCohort } from '../../../utils/request_cohort';

export default function WipeConfirmationModal() {
    // Modal State
    const open = useModalStore(state => state.showWipeConfirmationModal);
    const setOpen = useModalStore(state => state.setShowWipeConfirmationModal);

    // User State
    const selectedProject = useUserStore(state => state.selectedProject);
    const setSelectedProjectById = useUserStore(state => state.setSelectedProjectById);
    const token = useAuthStore(state => state.token);
    const refreshUser = useUserStore(state => state.refreshUser);

    // Toast Store
    const setShowSuccessToast = useToastStore(state => state.setShowSuccessfulWipeToast);

    // Local State
    const [ isLoading, setIsLoading ] = useState(false);

    const handleConfirmWipe = async() => {
        setIsLoading(true);
        if(selectedProject) {
          try {
            if(token){
            await removeBulkStudiesFromCohort(selectedProject.cohort.id, selectedProject.id, selectedProject.email, token);
            setSelectedProjectById(selectedProject?.id);
            setShowSuccessToast(true);
            }
          } catch (error) {
            console.error('Error wiping project:', error);
          }
          setIsLoading(false);
          setOpen(false);
        }
    } 


  const cancelButtonRef = useRef(null)

    return (
        <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
            <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                        <InformationCircleIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                            You are about to wipe your cohort
                        </Dialog.Title>
                        <div className="mt-2">
                        <p className="text-sm text-gray-600">
                            All of your selected studies will be removed from this cohort.
                        </p>
                        <br/>
                        <p className="text-sm text-gray-600">
                            <span className='font-semibold text-black'>Please note:</span> You can re-add these from the search afterwards.
                        </p>
                        </div>
                    </div>
                    </div>
                    <div className="mt-5 sm:ml-10 sm:mt-4 sm:flex sm:pl-4">
                    <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto"
                        onClick={handleConfirmWipe}
                    >
                        {!isLoading ?
                            "Confirm Wipe" :
                            "Confirming..."
                        }
                    </button>
                    <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                    >
                        Cancel
                    </button>
                    </div>
                </Dialog.Panel>
                </Transition.Child>
            </div>
            </div>
        </Dialog>
        </Transition.Root>
    )
}
