import { XMarkIcon } from "@heroicons/react/24/outline";
import { ParentItem, SearchTerm, SnomedDropDownRequest, SubChild } from "../../types/search";
import { useEffect, useRef, useState } from "react";
import { useSnomedSearchStore } from "../../store/snomedSearchStore";

type SnomedSearchBadgeProps = {
  searchTerm: SearchTerm;
  onRemove: (searchTerm: SearchTerm) => void;
  badgeNumber: number;
  cui: number;
};

enum ReadableOperator {
  "-" = "NOT",
  "+" = "AND",
  "OR" = "OR"
}

export default function SnomedSearchBadge({ searchTerm, onRemove, badgeNumber, cui }: SnomedSearchBadgeProps) {
  const SnomedDropDownDataArray = useSnomedSearchStore((state) => state.SnomedDropDownDataArray);
  const setSnomedDropDownDataForComponent = useSnomedSearchStore((state) => state.setSnomedDropDownDataForComponent);
  const isSnomedSearchLoader = useSnomedSearchStore((state) => state.isSnomedSearchLoader);
  const snomedDropdownRef = useRef<HTMLDivElement | null>(null);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [dropdownData, setDropdownData] = useState<SnomedDropDownRequest[]>(SnomedDropDownDataArray.filter((data) => data.cui === cui));
  const handleRemoval = () => {
    onRemove(searchTerm);
  };

  const toggleDropdown = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsDropDownOpen((prev) => !prev);
  };

  useEffect(() => {
    function handleMouseDown(event: MouseEvent) {
      if (snomedDropdownRef.current && !snomedDropdownRef.current.contains(event.target as Node)) {
        setIsDropDownOpen(false);
      }
    }
    document.addEventListener("mousedown", handleMouseDown);
    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);
  
  const handleCheckboxChange = (item: ParentItem | SubChild) => {
    const updateChildCheckStates = (children: SubChild[], isChecked: boolean): SubChild[] => {
      return children.map((child) => ({
        ...child,
        isChecked,
        children: child.children.length ? updateChildCheckStates(child.children, isChecked) : []
      }));
    };

    const updateDropDownData = (data: SubChild[], clickedCui: number, isChecked: boolean): SubChild[] => {
      return data.map((parent) => {
        if (parent.cui === clickedCui) {
          return {
            ...parent,
            isChecked,
            children: parent.children.length ? updateChildCheckStates(parent.children, isChecked) : []
          };
        }

        const updatedChildren = parent.children.length ? updateDropDownData(parent.children, clickedCui, isChecked) : parent.children;

        return {
          ...parent,
          children: updatedChildren
        };
      });
    };

    const findItem = (list: ParentItem[], cui: number, result: ParentItem[] = []): void => {
      list.forEach((item) => {
        if (item.cui === cui) {
          result.push(item);
        }
        if (item.children?.length) {
          findItem(item.children, cui, result);
        }
      });
    };

    const foundItem: ParentItem[] = [];
    findItem(dropdownData as any[], item.cui, foundItem);

    if (foundItem.length) {
      const selectedItem = foundItem[0];
      const isChecked = !selectedItem.isChecked;
      const updatedDropDownData: any = updateDropDownData(dropdownData as any, item.cui, isChecked);

      setDropdownData(updatedDropDownData);
    }
  };

  const recursiveFn = (item: SubChild) => {
    return (
      <div>
        <label className="flex items-center py-1 pl-2 pr-4 text-gray-900 cursor-pointer">
          <input type="checkbox" checked={item.isChecked} onChange={() => handleCheckboxChange(item)} className="mr-2" />
          {item.term || item.text}
        </label>

        {item.children?.length > 0 && (
          <div className="pl-6">
            {item.children.map((child: any, index: number) => (
              <div key={index}>{recursiveFn(child)}</div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const handleSave = () => {
    setSnomedDropDownDataForComponent(dropdownData, cui);
    setIsDropDownOpen(false);
  };

  useEffect(() => {
    setDropdownData([...SnomedDropDownDataArray.filter((data) => data.cui === cui)]);
  }, [SnomedDropDownDataArray]);

  return (
    <div>
      <span
        ref={snomedDropdownRef}
        className="inline-flex items-center gap-x-1.5 rounded-full bg-gray-500 px-2 py-1 text-xs font-medium text-white">
        <XMarkIcon className="h-3 w-3 hover:cursor-pointer" onClick={handleRemoval} />
        {badgeNumber === 0 ? `${searchTerm.term}` : `${ReadableOperator[searchTerm.operator]}: ${searchTerm.term}`}
        <span className="hover:cursor-pointer" onClick={toggleDropdown}>
          <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
          </svg>
        </span>
      </span>

      {isDropDownOpen && (
        <div
          ref={snomedDropdownRef}
          onClick={(e) => e.stopPropagation()}
          className="absolute left-3 z-50 mt-1 px-2 w-11/12 rounded-md border-0 bg-white shadow-lg max-h-80 overflow-y-scroll focus:outline-none">
          <div className="relative">
            {isSnomedSearchLoader ? (
              <div className="py-4 text-center text-gray-500">Loading...</div>
            ) : (
              <>
                {dropdownData.map((item: SnomedDropDownRequest) =>
                  item.children?.length === 0 ? (
                    <div key={item.cui} className="py-4 text-center text-gray-500">
                      No Children found
                    </div>
                  ) : (
                    <div key={item.cui} className="flex items-start flex-col pt-2">
                      {recursiveFn(item as any)}
                    </div>
                  )
                )}
              </>
            )}

            <div className="flex justify-center py-3 sticky bottom-0 bg-white w-full">
              <button
                onClick={handleSave}
                disabled={!!dropdownData.find((item) => item.children?.length === 0)}
                type="button"
                className="w-10/12 rounded bg-blue-600 hover:bg-blue-500 px-4 py-1 text-sm font-semibold text-white shadow-md disabled:bg-gray-400 disabled:cursor-not-allowed ">
                Save filter changes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
