import { Switch } from '@headlessui/react';
import cn from "classnames";
import { useFilterStore } from "../../../store/filterStore";
import { usePatientStore } from "../../../store/patientStore";
import { useSearchTypeStore } from '../../../store/searchTypeStore';
import { useEffect } from 'react';
import { alldropdowns } from '../dropdownList';
import { useFilterDropDownStore } from '../../../store/filterDropDownStore';

type USOnlyFilterProps = {
    id?: number;
}

export default function USOnlyFilter({id} : USOnlyFilterProps) {

    // Filter Store
    const usOnly = useFilterStore(state => state.usOnly);
    const setUsOnly = useFilterStore(state => state.setUsOnly);
    const setPatientUsOnly = usePatientStore(state => state.setUsOnly);
    const patientFilters = usePatientStore(state => state.filters)
    const searchType = useSearchTypeStore(state => state.searchType);
    const setFilterDropdowns = useFilterDropDownStore((state) => state.setFilterDropdowns);

    useEffect(()=>{
        setFilterDropdowns(alldropdowns);
    },[usOnly]);
    
    return (
        <>
        <div className='flex flex-row justify-between py-4'>
            <p className='text-base'>US Only</p>
            <div>
            <Switch
            //we are using  patientFilters[0] for both filters in patient search 
            checked={searchType === "patient" ? patientFilters[0].filters.us_only : usOnly}
            onChange={searchType === "patient" ? (v) => {setPatientUsOnly(v)} : setUsOnly}
            className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 z-0"
            >
            <span className="sr-only">Use setting</span>
            <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-slate-100" />
            <span
                aria-hidden="true"
                className={cn(
                //we are using  patientFilters[0] for both filters in patient search 
                ( searchType === "patient" ? patientFilters[0].filters.us_only : usOnly ) ? 'bg-blue-600' : 'bg-gray-200',
                'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out'
                )}
            />
            <span
                aria-hidden="true"
                className={cn(
                //we are using  patientFilters[0] for both filters in patient search 
                ( searchType === "patient" ? patientFilters[0].filters.us_only : usOnly ) ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out'
                )}
            />
            </Switch>
            </div>
    </div>
        </>
    );
}