import EmptyCatalogState from "./DisplayStates/emptyCatalog";
import Pagination from "./Pagination";
import NoResultsCatalog from "./DisplayStates/NoResultsCatalog";
import { useSearchStore } from "../../store/searchStore";
import StudyCard  from "./StudyCard";
import BulkAdd  from "./BulkAdd";
import { LoadingState } from "./DisplayStates/LoadingState";
import DownloadCsv from "./DownloadCsv";
import { useFilterStore } from "../../store/filterStore";
import Sorting from "./sorting/Sorting";
import { ShareIcon } from "@heroicons/react/20/solid";
import { useSearchTypeStore } from "../../store/searchTypeStore";
import { usePatientStore } from "../../store/patientStore";
import ResultsPerPage from "../common/ResultsPerPage";
import StudyCountDisplay from "./StudyCountDisplay";
import { useEffect } from "react";
import { useSnomedSearchStore } from "../../store/snomedSearchStore";


interface CatalogDisplayListProps{
    handleShareButtonClick: () => void;
    value: string;
    copied: boolean;
}

const CatalogDisplayList=({handleShareButtonClick, value, copied}:CatalogDisplayListProps)=> {

    // Search state
    const activeStudies = useSearchStore(state => state.activeStudies);
    const noResultsFound = useSearchStore(state => state.noResultsFound);
    const isRetrievingStudies = useSearchStore(state => state.isRetrievingStudies);
    const filters = useFilterStore(state => state.filters);
    const searchType = useSearchTypeStore((state) => state.searchType);
    const setSnomedSearchRequestPageOffset = useSnomedSearchStore(state => state.setSnomedSearchRequestPageOffset);
    const setPatientPaginationOffset = usePatientStore(state => state.setPatientPaginationOffset);
    const selectedPageSize = useSearchStore(state => state.selectedPageSize);
    const selectedSnomedPageSize = useSnomedSearchStore(state => state.selectedPageSize);
    const setSelectedSnomedPageSize = useSnomedSearchStore(state => state.setSelectedPageSize);
    const selectedPatientPageSize = usePatientStore(state => state.pagination);
    const setSelectedPatientPageSize = usePatientStore(state => state.setSelectedPatientPageSize);


    const handleSelectPageSize = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedPage = Number(event.target.value);
        setSnomedSearchRequestPageOffset(0);
        setPatientPaginationOffset(0);
        if(searchType === "study") {
            setSelectedSnomedPageSize(selectedPage);
        } else{
            setSelectedPatientPageSize(selectedPage);
        }
    }
    
    useEffect(() => {
        const handleScroll = () => {
          window.scrollTo(0, 0);
        };
  
        handleScroll();
      }, [selectedPageSize,selectedSnomedPageSize,selectedPatientPageSize]);
      
    return (
        <div className="flex flex-col justify-between">
            <div>
                <div className="flex flex-row items-center mb-4 justify-between hidden md:flex lg:flex xl:flex 2xl:flex">
                    <div className="flex flex-row items-center gap-1.5 cursor-pointer">
                        <h1 className="text-2xl font-semibold text-gray-700 border-b-2 border-slate-400">Search Results</h1>
                        <ShareIcon className="h-5 w-5 text-blue-700"
                            onClick={handleShareButtonClick}
                        />
                    </div>
                    <div className="flex flex-row items-center gap-x-4">
                        <StudyCountDisplay />
                        <Pagination />
                        <Sorting />
                        <BulkAdd/>
                        <DownloadCsv module="main"/>
                    </div>
                </div>
                <div>
                    {
                        !filters.cross_filter && isRetrievingStudies ? <LoadingState /> : (
                            activeStudies.length > 0 ?
                            activeStudies.map((studyMetadata, index ) => {
                                    return (
                                        <>
                                            <StudyCard key={`${studyMetadata.study_metadata.accession_number}-${index}`} studyMetadata={studyMetadata} page="catalog" />
                                        </>
                                    )
                                })
                            : ( noResultsFound ? 
                                <NoResultsCatalog /> :
                                <EmptyCatalogState />)
                            )
                    }
                </div>
                    {
                    !isRetrievingStudies && activeStudies.length > 0 ?
                    <div className="flex justify-end">
                            <ResultsPerPage 
                                selectedPageSize={searchType ==="study" ? selectedSnomedPageSize : selectedPatientPageSize.pageSize}
                                handleSelectPageSize={handleSelectPageSize}
                            />
                        <div className="flex justify-end py-4 items-center gap-x-4 pr-20">
                        <StudyCountDisplay />
                            <div>
                                <Pagination />
                            </div>
                        </div>
                    </div>
                        : null
                    }
            </div>
        </div>
    )
}
export default  CatalogDisplayList;