import { useSearchStore } from "../../store/searchStore";
import { useSearchTypeStore } from "../../store/searchTypeStore";
import { usePatientStore } from "../../store/patientStore";
import { useSnomedSearchStore } from "../../store/snomedSearchStore";

export const StudyCountDisplay = () => {
    // Search state
    const numberOfStudies = useSearchStore(state => state.numberOfStudies);
    const patientPagination = usePatientStore(state => state.pagination);
    const searchType = useSearchTypeStore(state => state.searchType);
    const isRetrievingStudies = useSearchStore(state => state.isRetrievingStudies);
    const isFetchingNumberOfStudies = useSearchStore(state => state.isFetchingNumberOfStudies);
    const selectedSnomedPageSize = useSnomedSearchStore(state => state.selectedPageSize);
    const selectedPatientPageSize = usePatientStore(state => state.pagination);
    // Snomed Search state
    const snomedSearchRequestPageOffset = useSnomedSearchStore(state => state.snomedSearchRequestPageOffset);
    const currentPageOffset = searchType === "study"?  snomedSearchRequestPageOffset : patientPagination.offset;
    const PAGE_SIZE = searchType === "study"? selectedSnomedPageSize : selectedPatientPageSize.pageSize;
    const highestPossibleStudies = currentPageOffset * PAGE_SIZE + 1;
    const result = Math.min((currentPageOffset + 1) * PAGE_SIZE, numberOfStudies || 0 );

    return (
        <div>
        {
            !numberOfStudies && (isFetchingNumberOfStudies || isRetrievingStudies) ?
                <span className="text-gray-600 text-sm">Loading Study Count...</span> :
                numberOfStudies ? 
                    <p className="text-gray-600 text-sm ml-2">
                        Showing 
                        <span className="font-medium">&nbsp;{highestPossibleStudies}</span> - 
                        <span className="font-medium">&nbsp;{result}
                        </span> of <span className="font-medium">{numberOfStudies.toLocaleString()}</span>
                    </p> :
                    null
        }
        </div>
    );
};

export default StudyCountDisplay;