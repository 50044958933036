import React from 'react';
import PieChart from './common/PieChart';
import { useChartsStore } from '../../../../../store/chartsStore';

export default function SourcePieChart() {
  const cohortsChartsData = useChartsStore((state) => state.cohortsChartsData);
  const { institution: sourcePieData } = cohortsChartsData;

  const getSourcePieChartData = () => {
    let ranges: { [key: string]: number } = {
      Brazil: 0,
      "United States": 0,
      Other: 0
    };

    if (!sourcePieData) {
      return ranges;
    }

    Object.keys(sourcePieData).forEach((key) => {
      if (key.startsWith("tachyeres") || key.startsWith("thryothor") || key.startsWith("peregrine") || key.startsWith("quelea") || key.startsWith("xenops")) {
        ranges["United States"] += Number(sourcePieData[key]);
      } else {
        ranges["Brazil"] += Number(sourcePieData[key]);
      }
    });
    return ranges;
  };

  const sourcePieChartData = getSourcePieChartData();

  return (
    <PieChart
      data={sourcePieChartData}
      colors={["#3B82F6", "#60A5FA","#0000FF"]}
      chartId="source-pie-chart"
    />
  );
};