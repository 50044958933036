import { useState, useEffect } from "react";
import SearchBadge from "./searchbadge";
import SnomedSearchBadge from "./snomedSearchBadge";
import SearchBar from "./searchbar";
import { useFilterStore } from "../../store/filterStore";
import { SearchTerm,SnomedDropDownRequest,SnomedInitialSearchRequest, SnomedTerm,SearchSnomedTerm, SubChild, ParentItem } from "../../types/search";
import { usePatientStore } from '../../store/patientStore';
import { useSnomedSearchStore } from "../../store/snomedSearchStore";
import { formatSearchQueryForLucene } from "../../utils/lucene_helpers";
import { useSearchTypeStore } from "../../store/searchTypeStore";

type SearchProps = {
    id?: number;
}

export default function Search({id}: SearchProps) {
    const [ searchOperator, setSearchOperator ] = useState<'+' | '-' | 'OR'>('+');
    const searchTerms = useFilterStore(state => state.searchTerms);
    const setSearchTerms = useFilterStore(state => state.setSearchTerms);
    const setPatientSearchTerms = usePatientStore(state => state.setSearchTerms);
    const searchFilters = useFilterStore(state => state.filters);
    const patientFilters = usePatientStore(state => state.filters);
    const searchType = useSearchTypeStore(state => state.searchType);

    
    // Snomed Search store
    const snomedSearchRequest = useSnomedSearchStore(state => state.snomedSearchRequest);
    const setSnomedSearchRequest = useSnomedSearchStore(state => state.setSnomedSearchRequest);
    const setAutoCompleteData = useSnomedSearchStore(state => state.setAutoCompleteData);
    const snomedbadgeCount = useSnomedSearchStore(state => state.snomedbadgeCount);
    const setSnomedBadgeCount = useSnomedSearchStore(state => state.setSnomedBadgeCount);
    const SnomedDropDownDataArray = useSnomedSearchStore(state => state.SnomedDropDownDataArray); 
    const setSnomedDropDownDataArray = useSnomedSearchStore(state => state.setSnomedDropDownDataArray); 

    useEffect(() => {
        const count = snomedSearchRequest?.searchTerms?.filter((term) => term.snomed_tags?.length).length;
        setSnomedBadgeCount(count);
    }, [snomedSearchRequest, snomedbadgeCount]);

  const handleRemoveSearchTerm = (searchTermToRemove: SearchTerm, cui?: number) => {
        setSnomedDropDownDataArray(
          SnomedDropDownDataArray.filter(
            (data) =>
              data.cui !== cui ||
              data.operator !== searchTermToRemove.operator
          )
        );
          const filteredTerms = snomedSearchRequest?.searchTerms.filter(
            (term) =>
              !(
                (term.term === searchTermToRemove.term &&
                  term.operator === searchTermToRemove.operator) ||
                (term.parent_snomed_tag.term === searchTermToRemove.term &&
                  term.operator === searchTermToRemove.operator)
              )
          );
          const existingRawText = filteredTerms?.filter(
            (term) => term.snomed_tags?.length === 0 && term.term !== ""
          );
          const existingSnomedTag = filteredTerms?.filter(
            (term) =>
              term.snomed_tags?.length > 0 && term.term === ""
          );
          
          if (existingSnomedTag?.length <= 0) {
            setAutoCompleteData([]);
            setSearchOperator("+");
            setSnomedSearchRequest({
              ...snomedSearchRequest,
              searchTerms: filteredTerms,
              search_query: formatSearchQueryForLucene(filteredTerms),
              is_snomed_search: false
            });
          } else {
            setSnomedSearchRequest({
              ...snomedSearchRequest,
              searchTerms: filteredTerms,
              search_query: formatSearchQueryForLucene(filteredTerms),
              is_snomed_search: true
            });
      }
        let updatedSearchTerms;
        if (id != null) {
          updatedSearchTerms = patientFilters[id].filters.searchTerms.filter(
            (searchTerm: SearchTerm) =>
              searchTerm.term !== searchTermToRemove.term
          );
          setPatientSearchTerms(updatedSearchTerms, id);
        }

        if (updatedSearchTerms?.length === 0) {
          setSearchOperator("+");
        }
    };

    function addSnomedTags(
        searchPayload: SnomedInitialSearchRequest,
        termsArray: SnomedDropDownRequest[]
      ) {

        // Recursive function to process children and filter out unchecked items
        function processChildren(children: SubChild[]) {
          let snomedTags: { term: string; cui: number }[] = [];
      
          children?.forEach((child: ParentItem) => {
            if (child?.isChecked) {
              // Add the child's SNOMED tag if checked
              let childTag: { term: string; cui: number; } = {
                term: child.text,
                cui: child.cui,
              };
      
              // If the child has children, process them recursively
              if (child?.children && child?.children?.length > 0) {
                let childSnomedTags = processChildren(child.children);
                if (childSnomedTags?.length > 0) {
                snomedTags.push(...childSnomedTags);

                }
              }
      
              // Add the child's tag to the snomedTags list
              snomedTags.push(childTag);
            }
          });
      
          return snomedTags;
        }
        let tempSearchPayload = { ...searchPayload };
        tempSearchPayload.is_snomed_search = false;
        // Add raw tags to snomed search
        if (searchTerms.length > 0) {
          const searchTermsToSnomedTerms: SearchSnomedTerm[] = searchTerms.map(
            (searchTerm) => {
              return {
                operator: searchTerm.operator,
                parent_snomed_tag: { cui: 0, term: "" },
                snomed_tags: [],
                term: searchTerm.term.replace(/["']/g, ''),

              };
            }
          );
          tempSearchPayload.searchTerms = [...tempSearchPayload.searchTerms,...searchTermsToSnomedTerms];
          setSearchTerms([]);
        }
        // Iterate over each term in the termsArray
        termsArray?.forEach((term: { term: string; isChecked: boolean; children: SubChild[] ;cui:number ;operator:'+' | '-'| 'OR'}) => {

            let snomedTagsArray:SnomedTerm[] =[]
          if (term?.isChecked) {
            snomedTagsArray.push({
                term: term.term,
                cui: term.cui,
              })
            }
              // Process children if they exist, add checked ones, and remove unchecked
              if (term?.children && term?.children?.length > 0) {
                let childrenTags = processChildren(term.children);
                if (childrenTags?.length > 0) {
                    snomedTagsArray.push(...childrenTags);
                }
              }
              const indexOfSnomedTag = tempSearchPayload.searchTerms.findIndex(
                (searchTerm) => searchTerm.parent_snomed_tag.cui === term.cui && searchTerm.operator === term.operator
              );
              if (indexOfSnomedTag > -1) {
                if (snomedTagsArray.length > 0 && tempSearchPayload.searchTerms[indexOfSnomedTag].term === "") {
                  tempSearchPayload.searchTerms[indexOfSnomedTag].snomed_tags =
                    snomedTagsArray;
                    tempSearchPayload.is_snomed_search = true;
                } else
                  tempSearchPayload.searchTerms =
                    tempSearchPayload.searchTerms.filter(
                      (term, index) => index !== indexOfSnomedTag
                    );
              } else {
                if (snomedTagsArray.length > 0) {
                  tempSearchPayload.is_snomed_search = true;
                  tempSearchPayload.searchTerms.push({
                    snomed_tags: snomedTagsArray,
                    term: "",
                    parent_snomed_tag: {
                      term: term.term,
                      cui: term.cui,
                    },
                    operator: term.operator,
                  });
                }
              }
        });

        // Flatten the new tags array to get the search terms
        const existingTags: SearchTerm[] = [];
        tempSearchPayload.searchTerms.forEach(
          (searchTerm: SearchSnomedTerm) => {
            if (searchTerm.snomed_tags[0]?.term) {
              searchTerm.snomed_tags.forEach((snomedTag, index) => {
                existingTags.push({
                  term: snomedTag.term,
                  operator: index === 0 ? searchTerm.operator : ("OR" as const),
                });
              });
            } else {
              existingTags.push({
                term: searchTerm.term,
                operator: searchTerm.operator,
              });
            }
          }
        );        

        
      const searchQuery = formatSearchQueryForLucene(existingTags);
      const newSnomedSearchPayload =  {
        ...searchPayload,
        search_query: searchQuery,
        is_snomed_search: tempSearchPayload.is_snomed_search,
        searchTerms: tempSearchPayload.searchTerms,
      };
      // Return the newly created payload
      return newSnomedSearchPayload;
    }
      
       useEffect(() => {
         // Generate snomed tags search terms and search query and update global search payload.
           const updatedPayload = addSnomedTags(
             snomedSearchRequest,
             SnomedDropDownDataArray
           );
           setSnomedSearchRequest({ ...updatedPayload });
       }, [SnomedDropDownDataArray]);

        return (
   
        <div className="flex flex-col">
        <SearchBar id={id} searchOperator={searchOperator} setSearchOperator={setSearchOperator} />
            <div className="py-1 flex flex-row flex-wrap gap-x-2 gap-y-2">
                {id != null && patientFilters[id].filters.searchTerms.map((searchTerm: SearchTerm, index: number) => {
                    return <SearchBadge searchTerm={searchTerm} key={index} badgeNumber={index} onRemove={(searchTerm) => handleRemoveSearchTerm(searchTerm)} />
                })}

            {(searchType === "study") &&
                <div className="flex gap-2 flex-wrap">
                   {searchFilters?.searchTerms?.map((searchTerm: SearchTerm, index: number) => (
                    <SearchBadge 
                        searchTerm={searchTerm} 
                        key={index} 
                        badgeNumber={index} 
                        onRemove={(searchTerm) => handleRemoveSearchTerm(searchTerm)}
                      />
                    ))}
                    {SnomedDropDownDataArray?.map((tag,snomedIndex) => (
                    <SnomedSearchBadge
                        searchTerm={{ operator: tag.operator, term: tag.term }}
                        key={snomedIndex}
                        cui={tag.cui}
                        badgeNumber={snomedIndex}
                        onRemove={(searchTerm) => handleRemoveSearchTerm(searchTerm, tag.cui)}
                        />
                      ))}
                    {snomedSearchRequest?.searchTerms?.map((item, index) => {                                       
                        if (item?.term && item?.snomed_tags?.length === 0) {                    
                            return (
                            <SearchBadge
                                searchTerm={{operator: item.operator,term: item.term}}
                                key={index}
                                badgeNumber={snomedSearchRequest.is_snomed_search ? index+1 : index}
                                onRemove={handleRemoveSearchTerm}
                            />
                            );
                        }
                    })}
                </div>
              }
            </div>
        </div>
   
    )
}