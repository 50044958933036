import { create } from "zustand";
import { useSearchStore } from "./searchStore";
import { SearchFilters, SearchTerm } from "../types/search";
import { formatSearchQueryForLucene } from "../utils/lucene_helpers";
import { manufacturerSelectionsDefault } from "../components/filterControls/manufacturerFilter/manufacturerOptions";
import { modalityOptionsDefault } from "../components/filterControls/modalityFilter/modalityOptions";
const PAGE_SIZE = 10;

interface PatientStore {
    filters: any[];
    timeBetween: number[];
    setTimeBetween: (timeBetween: number[]) => void;
    pagination: any;
    setPatientFilters: (filters: any[]) => void;
    setPatientPaginationOffset: (offset: number) => void;
    setSearchTerms: (searchTerms: SearchTerm[], id: number) => void;
    setSexSelections: (sexSelections: string[]) => void;
    setUsOnly: (usOnly: boolean) => void;
    setModalitySelections: (modalitySelections: string[], id: number) => void;
    setManufacturerSelections: (manufacturerSelections: string[], id: number) => void;
    setAgeSelections: (ageSelections: number[]) => void;
    setSliceThicknessSelections: (sliceThicknessSelections: number[], id: number) => void;
    handlePatientResetFilters: () => void;
    clearButtonClicked: boolean;
    setClearButtonClicked: (clearButtonClicked: boolean)=>void;
    setSelectedPatientPageSize: (numberOfStudies: number) => void;
    patientPagination: number;
    setPatientPagination: (patientPagination: number)=>void;
}

const filterStore1 = {
    filters: {
        searchTerms: [],
        modality: modalityOptionsDefault.filter(item=> item!== "null" && item !== "UNKNOWN"),
        us_only: false,
        age: {
            min: 0,
            max: 90
        },
        sex: ["M", "F", "O", "U"],
        manufacturer: manufacturerSelectionsDefault.filter(item=> item!== "null" && item !== "UNKNOWN"),
        slice_thickness: {
            min: 0,
            max: 10
        }
    },
    usOnly: false,
    sexSelections: ["M", "F", "O", "U"],
    modalitySelections: modalityOptionsDefault.filter(item=> item!== "null" && item !== "UNKNOWN"),
    manufacturerSelections: manufacturerSelectionsDefault.filter(item=> item!== "null" && item !== "UNKNOWN"),
    ageSelections: [0, 90],
    sliceThicknessSelections: [0, 10.0],
};

const filterStore2 = {
    filters: {
        searchTerms: [],
        modality: modalityOptionsDefault.filter(item=> item!== "null" && item !== "UNKNOWN"),
        us_only: false,
        age: {
            min: 0,
            max: 90
        },
        sex: ["M", "F", "O", "U"],
        manufacturer: manufacturerSelectionsDefault.filter(item=> item!== "null" && item !== "UNKNOWN"),
        slice_thickness: {
            min: 0,
            max: 10
        }
    },
    searchTerms: [],
    usOnly: false,
    sexSelections: ["M", "F", "O", "U"],
    modalitySelections: modalityOptionsDefault.filter(item=> item!== "null" && item !== "UNKNOWN"),
    manufacturerSelections: manufacturerSelectionsDefault.filter(item=> item!== "null" && item !== "UNKNOWN"),
    ageSelections: [0, 90],
    sliceThicknessSelections: [0, 10.0],
};

export const InitialPatientFilter = {
  filters: [
    {
      filters: {
        searchTerms: [],
        modality: modalityOptionsDefault.filter(item=> item!== "null" && item !== "UNKNOWN"),
        us_only: false,
        age: {
          min: 0,
          max: 90,
        },
        sex: ["M", "F", "O", "U"],
        manufacturer: manufacturerSelectionsDefault.filter(item=> item!== "null" && item !== "UNKNOWN"),
        slice_thickness: {
          min: 0,
          max: 10,
        },
      },
      modalitySelections: modalityOptionsDefault.filter(item=> item!== "null" && item !== "UNKNOWN"),
      search_summary: "",
      search_query: "",
    },
    {
      filters: {
        searchTerms: [],
        modality: modalityOptionsDefault.filter(item=> item!== "null" && item !== "UNKNOWN"),
        us_only: false,
        age: {
          min: 0,
          max: 90,
        },
        sex: ["M", "F", "O", "U"],
        manufacturer: manufacturerSelectionsDefault.filter(item=> item!== "null" && item !== "UNKNOWN"),
        slice_thickness: {
          min: 0,
          max: 10,
        },
      },
      modalitySelections: modalityOptionsDefault.filter(item=> item!== "null" && item !== "UNKNOWN"),
      search_summary: "",
      search_query: "",
    },
  ],
  pagination: {
    pageSize: PAGE_SIZE,
    offset: 0,
  },
  timeBetween: [-1000000, 1000000],
};

export const usePatientStore = create<PatientStore>()((set) => ({
    filters: [filterStore1, filterStore2],
    pagination: {
        offset: 0,
        pageSize: PAGE_SIZE
    },
    timeBetween: [-1000000, 1000000],
    setTimeBetween: (timeBetween: number[]) => set(state => {
        return {
            ...state,
            timeBetween: timeBetween
        }
    }),
    setPatientFilters: (filters: any[]) => set(state => {
        return {
            ...state,
            filters: filters
        }
    }),
    setPatientPaginationOffset: (offset: number) => set(state => {
        return {
            ...state,
            pagination:{
                ...state.pagination,
                offset: offset
            }
        }
    }),
    setSelectedPatientPageSize: (selectedPageSize: number) => set(state => {
        return {
            ...state,
            pagination:{
                ...state.pagination,
                pageSize: selectedPageSize
            }
        }
    }),
    setSearchTerms: (searchTerms: SearchTerm[], id: number) => set(state => {
        return id == 0 ? {
            ...state,
            filters:[
                {
                    filters: {
                        ...state.filters[0].filters,
                        searchTerms: searchTerms
                    },
                    searchTerms
                },
                state.filters[1]
            ]
        } : {
            ...state,
            filters:[
                state.filters[0],
                {
                    filters: {
                        ...state.filters[1].filters,
                        searchTerms: searchTerms
                    },
                    searchTerms
                }
            ]
        }
    }),
    setUsOnly: (usOnly: boolean) => set(state => {
        const new_state = {
            ...state,
            filters: [
                {
                    filters: {
                        ...state.filters[0].filters,
                        us_only: usOnly
                    },
                    usOnly
                },
                {
                    filters: {
                        ...state.filters[1].filters,
                        us_only: usOnly
                    },
                    usOnly
                }
            ]
        };
        return new_state;
    })
    ,
    setSexSelections: (sexSelections: string[]) => set(state => {
        const new_state = {
            ...state,
            filters: [
                {
                    filters: {
                        ...state.filters[0].filters,
                        sex: sexSelections
                    },
                    sexSelections
                },
                {
                    filters: {
                        ...state.filters[1].filters,
                        sex: sexSelections
                    },
                    sexSelections
                }
            ]
        };
        return new_state;
    })
    ,
    setModalitySelections: (modalitySelections: string[], id: number) => set(state => {
        return id == 0 ? {
            ...state,
            filters:[
                {
                    filters: {
                        ...state.filters[0].filters,
                        modality: modalitySelections.filter(item=> item!== "null" && item !== "UNKNOWN")
                    },
                    modalitySelections: modalitySelections.filter(item=> item!== "null" && item !== "UNKNOWN")
                },
                state.filters[1]
            ]
        } : {
            ...state,
            filters:[
                state.filters[0],
                {
                    filters: {
                        ...state.filters[1].filters,
                        modality: modalitySelections.filter(item=> item!== "null" && item !== "UNKNOWN")
                    },
                    modalitySelections: modalitySelections.filter(item=> item!== "null" && item !== "UNKNOWN")
                }
            ]
        }
    }),
    setManufacturerSelections: (manufacturerSelections: string[], id: number) => set(state => {
        return id == 0 ? {
            ...state,
            filters:[
                {
                    filters: {
                        ...state.filters[0].filters,
                        manufacturer: manufacturerSelections.filter(item=> item!== "null" && item !== "UNKNOWN")
                    },
                    manufacturerSelections: manufacturerSelections.filter(item=> item!== "null" && item !== "UNKNOWN")
                },
                state.filters[1]
            ]
        } : {
            ...state,
            filters:[
                state.filters[0],
                {
                    filters: {
                        ...state.filters[1].filters,
                        manufacturer: manufacturerSelections.filter(item=> item!== "null" && item !== "UNKNOWN")
                    },
                    manufacturerSelections: manufacturerSelections.filter(item=> item!== "null" && item !== "UNKNOWN")
                }
            ]
        }
    }),
    setAgeSelections: (ageSelections: number[]) => set(state => {
        const new_state = {
            ...state,
            filters: [
                {
                    filters: {
                        ...state.filters[0].filters,
                        age: {
                            min: ageSelections[0],
                            max: ageSelections[1]
                        }
                    },
                    ageSelections
                },
                {
                    filters: {
                        ...state.filters[1].filters,
                        age: {
                            min: ageSelections[0],
                            max: ageSelections[1]
                        }
                    },
                    ageSelections
                }
            ]
        };
        return new_state;
    })
    ,
    setSliceThicknessSelections: (sliceThicknessSelections: number[], id: number) => set(state => {
        return id == 0 ? {
            ...state,
            filters:[
                {
                    filters: {
                        ...state.filters[0].filters,
                        slice_thickness: {
                            min: sliceThicknessSelections[0],
                            max: sliceThicknessSelections[1]
                        }
                    },
                    sliceThicknessSelections
                },
                state.filters[1]
            ]
        } : {
            ...state,
            filters:[
                state.filters[0],
                {
                    filters: {
                        ...state.filters[1].filters,
                        slice_thickness: {
                            min: sliceThicknessSelections[0],
                            max: sliceThicknessSelections[1]
                        }
                    },
                    sliceThicknessSelections
                }
            ]
        }
    }),
    handlePatientResetFilters: () => set({    
        ...InitialPatientFilter
    }),
    clearButtonClicked: false,
    setClearButtonClicked: (clearButtonClicked:boolean)=> set({clearButtonClicked}),
    patientPagination: 0,
    setPatientPagination: (patientPagination:number)=> set({patientPagination}),
}))