import { Fragment, useEffect, useRef, useState} from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { useUserStore } from '../../store/userStore'
import { useModalStore } from '../../store/modalStore'
import { useProjectsStore } from '../../store/projectsStore'
import { PencilIcon } from '@heroicons/react/24/outline'
import { getTeamsCohortNumber } from '../../utils/request_for_team_projects'
import { useAuthStore } from '../../store/authStore'
import { Project } from '../../types/project'
function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

export default function ProjectsDropdown(props: { teamProjects: Project[] }) {
   
    // User State
    const projects = useUserStore(state => state.projects);
    const selectedProject = useUserStore(state => state.selectedProject);
    const setSelectedProjectById = useUserStore(state => state.setSelectedProjectById);
    const setSelectedProject = useUserStore(state => state.setSelectedProject);
    const setSelectedProjectOwnerEmail= useUserStore(state => state.setSelectedProjectOwnerEmail);
    const gradientUser = useUserStore(state => state.gradientUser);
    // Modal State
    const setShowCreateNewProjectModal = useModalStore(state => state.setShowCreateNewProjectModal);
    const setShowEditProjectNameModal = useModalStore(state => state.setShowEditProjectNameModal);

    // Project State
    const setSelectedProjectForEditName = useProjectsStore(state => state.setSelectedProjectForEditName);
    const setStudiesCountInCohort = useProjectsStore(state => state.setStudiesCountInCohort);
 
    // Auth state
    const token = useAuthStore(state => state.token);
 
    //local State
    const [hoveredProjectId, setHoveredProjectId] = useState<number | null>(null);
    const [tabName, setTabName] = useState<string>('myProjects');
    const [isOpen, setIsOpen] = useState(false);
 
    const dropdownRef = useRef<HTMLDivElement | null>(null);
 
    const handleProjectSelect = (id: number) => {
        setSelectedProjectById(id);
    }

    const handleCreateNewProject = () => {
        setShowCreateNewProjectModal(true);
        setIsOpen(false);
    }

    const handleToRenameProject = (project:any) => {
      setShowEditProjectNameModal(true);
      const selectedProjectForEditName = {
        id: project.id,
        project_name: project.project_name,
        isEditableByTeam: project.is_editable_by_team,
      };
      setSelectedProjectForEditName(selectedProjectForEditName);
  }

  const handleMouseEnter = (projectId: number) => {
    setHoveredProjectId(projectId);
  };

  const handleMouseLeave = () => {
    setHoveredProjectId(null);
  };
 
  const handleTabName = (tabName: string) => {
    setTabName(tabName);
  }
const getTeamProjectCount=async (project:any)=>{
  try{
    if(token){
      const cohortData= await getTeamsCohortNumber( project.id,project.cohort.id,project.email, token);
      if(cohortData){
      setSelectedProjectById(project?.id);
      const teamsCohortData=  {
      id: project?.id,
      project_name: project?.project_name,
      email: project?.email,
      cohort: {status:cohortData.cohorts.status,id:project.cohort.id,studies:cohortData.cohorts.studies},
      is_editable_by_team: project?.is_editable_by_team
      }
      setSelectedProject(teamsCohortData)
      }
    }
 
  }
  catch(error){
    console.log('error',error);
  }
 
 
}
 
  const handleSelectProject=(id:any)=>{
    setSelectedProjectOwnerEmail(gradientUser?.email);
    setSelectedProjectById(id);
    setStudiesCountInCohort(null);
    setIsOpen(false);
  }
 
  const handleTeamsProject=(project:any)=>{
    setSelectedProjectById(project.id);
    getTeamProjectCount(project);
    setSelectedProjectOwnerEmail(project.email);
    setStudiesCountInCohort(null);
    setIsOpen(false);
  }
 
  useEffect(()=>{
    setTabName('myProject');
    setSelectedProjectOwnerEmail(gradientUser?.email);
  },[token])
 
  useEffect(() => {
    function handleMouseDown(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleMouseDown);
 
    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    }
  },[])
 
 
  return (
    <Listbox value={selectedProject?.id} onChange={handleProjectSelect}>
      {({ open }) => (
        <>
          <Listbox.Label className="hidden md:block text-sm font-medium leading-6 text-gray-900  mt-2">Current Project: </Listbox.Label>
          <div className="relative mt-2" ref={dropdownRef}>
            <Listbox.Button
              className="relative w-96 cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2sm:text-sm sm:leading-6"
              onClick={() => setIsOpen((prev) => !prev)}>
              <span className="block truncate">{selectedProject?.project_name}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>
 
            <Transition show={isOpen} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                <Listbox.Option className="flex justify-between text-center relative cursor-pointer border-b border-grey-900" value="new1">
                  <>
                    <Listbox.Option
                      value="myProjects"
                      className={` ${
                        tabName === "myProject"
                          ? "border-b-[1px] border-solid border-b-neutral-950 text-grey-900 font-bold"
                          : "text-slate-400"
                      } py-2 px-4 text-md w-1/2`}
                      onClick={() => handleTabName("myProject")}>
                      My Projects
                    </Listbox.Option>
                    <Listbox.Option
                      value="team-projects"
                      className={` ${
                        tabName === "team-projects"
                          ? "border-b-[1px] border-solid border-b-neutral-950 text-grey-900 font-bold"
                          : "text-slate-400"
                      } py-2 px-4 text-md w-1/2`}
                      onClick={() => handleTabName("team-projects")}>
                      Team Projects
                    </Listbox.Option>
                  </>
                </Listbox.Option>
 
                {tabName === "myProject" && (
                  <>
                    {projects?.map((project:any) => (
                      <Listbox.Option
                        key={project?.id}
                        className={`relative cursor-pointer select-none py-2 pl-3 pr-9 flex justify-between hover:bg-gray-200 ${
                          project.id === hoveredProjectId
                            ? 'bg-gray-200 text-black'
                            : 'text-gray-900'
                        }`}
                        value={project?.id}
                        onMouseEnter={() => handleMouseEnter(project?.id)}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => handleSelectProject(project?.id)}>
                        {({ selected, active }) => (
                          <>
                            <span className={classNames(selected ? "font-semibold" : "font-normal", "ml-8 block truncate")}>
                              {project?.project_name}
                            </span>
                            {hoveredProjectId === project?.id && (
                              <PencilIcon
                                className="size-6 h-5 ml-4 mt-1 cursor-pointer text-grey-700 w-2/5"
                                onClick={() => handleToRenameProject(project)}
                              />
                            )}
                            {selected ? (
                              <span className="text-blue-600 absolute inset-y-0 left-2 flex items-center">
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                    <Listbox.Option
                      onClick={handleCreateNewProject}
                      className={({ active }) =>
                        classNames(
                          active ? "bg-gray-200 text-black" : "text-gray-900",
                          "text-center relative cursor-pointer  select-none py-2 px-4 border-t border-gray-200 hover:underline "
                        )
                      }
                      value="new1">
                      {({ selected, active }) => (
                        <>
                          <span className="font-semibold text-sm">+ Create New Project</span>
                        </>
                      )}
                    </Listbox.Option>
                  </>
                )}
 
                {tabName === "team-projects" && (
                  <>
                    {props.teamProjects?.map((project: any, index: number) => (
                      <Listbox.Option
                        key={index}
                        className={`relative cursor-pointer select-none py-2 pl-3 pr-9 flex justify-between hover:bg-gray-200 ${
                          project.id === hoveredProjectId
                            ? 'bg-gray-200 text-black'
                            : 'text-gray-900'
                        }`}
                        value={project?.id}
                        onMouseEnter={() => handleMouseEnter(project?.id)}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => handleTeamsProject(project)}>
                        {({ selected, active }) => (
                          <>
                            <span className={classNames(selected ? "font-semibold" : "font-normal", "ml-8 block truncate w-1/2")}>
                              {project?.project_name}
                            </span>
                            <span
                              className={classNames(
                                selected ? "font-semibold text-gray-400" : "font-normal",
                                "ml-8 block truncate text-gray-300 w-1/2"
                              )}>
                              {project?.email}
                            </span>
                            {selected ? (
                              <span className="text-blue-600 absolute inset-y-0 left-2 flex items-center">
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                    {props.teamProjects.length===0 && (
                      <Listbox.Option
                        className={({ active }) =>
                          classNames(
                            active ? "bg-gray-200 text-black" : "text-gray-900",
                            "text-center relative py-2 px-4 border-t border-gray-200"
                          )
                        }
                        value="new1">
                        {({ selected, active }) => (
                            <span className="font-semibold text-sm">No Projects to show</span>
                        )}
                      </Listbox.Option>
                    )}
                  </>
                )}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
 