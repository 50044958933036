import { create } from 'zustand';
import { SearchFilters, SearchRequest, Study } from '../types/search';
import { modalityOptionsDefault } from '../components/filterControls/modalityFilter/modalityOptions';
import { manufacturerSelectionsDefault } from '../components/filterControls/manufacturerFilter/manufacturerOptions';
const PAGE_SIZE = 10;


interface StudiesByPageObject {
    [key: number]: Study[];
}

// Advanced Filters should be bundled into this store and format
interface SearchStore {
    searchRequestPageOffset: number;
    setSearchRequestPageOffset: (searchRequestPageOffset: number) => void;
    searchRequest: SearchRequest;
    setSearchRequest: (searchRequest: SearchRequest) => void;
    updateSearchQuery: (searchQuery: string) => void;
    updateSearchRequestFilters: (filters: SearchFilters) => void;
    updateSearchSummary: (searchSummary: boolean) => void;
    updateSortBy: (searchSummary: any) => void;
    studies: StudiesByPageObject;
    setStudies: (studies: StudiesByPageObject) => void;
    activeStudies: Study[];
    setActiveStudies: (activeStudies: Study[]) => void;
    shouldFetchStudies: boolean;
    setShouldFetchStudies: (shouldFetchStudies: boolean) => void;
    isRetrievingStudies: boolean;
    setIsRetrievingStudies: (isRetrievingStudies: boolean) => void;
    noResultsFound: boolean;
    setNoResultsFound: (noResultsFound: boolean) => void;
    numberOfStudies: number | null;
    setNumberOfStudies: (numberOfStudies: number | null) => void;
    disableSearch: boolean;
    setDisableSearch: (disableSearch: boolean) => void;
    shouldFetchNumberOfStudies: boolean;
    setShouldFetchNumberOfStudies: (hasNumberOfStudiesBeenFetched: boolean) => void;
    isFetchingNumberOfStudies: boolean;
    setIsFetchingNumberOfStudies: (isFetchingNumberOfStudies: boolean) => void;
    dataStoreSearchParams: any;
    setdataStoreSearchParams : (dataStoreSearchParams: any) => void;
    clearButtonClicked: boolean;
    setClearButtonClicked: (clearButtonClicked: boolean)=>void;
    selectedPageSize: number;
    setSelectedPageSize: (numberOfStudies: number) => void;
    studiesPagination: number;
    setStudiesPagination: (studiesPagination: number)=>void;
}

export const initialSearchRequest: SearchRequest = {
    search_query: '',
    search_summary: false,
    filters: {
        us_only: false,
        cross_filter:false,
        age: {
            min: 0,
            max: 90
        },
        sex: ["M", "F", "O", "U"],
        slice_thickness: {
            min: 0,
            max: 10
        },
        modality: modalityOptionsDefault.filter(item=> item!== "null" && item !== "UNKNOWN"),
        manufacturer: manufacturerSelectionsDefault.filter(item=> item!== "null" && item !== "UNKNOWN"),
        searchTerms: [],
    },
    pagination: {
        pageSize: PAGE_SIZE,
        offset: 0
    },
}

export const useSearchStore = create<SearchStore>()((set) => ({
    searchRequestPageOffset: 0,
    searchRequest: {
        search_query: '',
        search_summary: false,
        filters: {
            modality: modalityOptionsDefault.filter(item=> item!== "null" && item !== "UNKNOWN"),
            us_only: false,
            cross_filter:false,
            age: {
                min: 0,
                max: 90
            },
            sex: ["M", "F", "O", "U"],
            manufacturer: manufacturerSelectionsDefault.filter(item=> item!== "null" && item !== "UNKNOWN"),
            slice_thickness: {
                min: 0,
                max: 10
            },
            searchTerms: [],
        },
        pagination: {
            pageSize: PAGE_SIZE,
            offset: 0
        },
    },
    setSearchRequest: (searchRequest: SearchRequest) => set((state) => {
        return {
            searchRequest: {
                ...searchRequest
            }
        }
    }),
    studies: {},
    activeStudies: [],
    shouldFetchStudies: false,
    isRetrievingStudies: false,
    noResultsFound: false,
    numberOfStudies: null,
    disableSearch: false,
    isFetchingNumberOfStudies: false,
    shouldFetchNumberOfStudies: false,
    dataStoreSearchParams: {
        search_query: '',
        search_summary: false,
        filters: {
            modality: modalityOptionsDefault.filter(item=> item!== "null" && item !== "UNKNOWN"),
            us_only: false,
            age: {
                min: 0,
                max: 90
            },
            sex: ["M", "F", "O", "U"],
            manufacturer: manufacturerSelectionsDefault.filter(item=> item!== "null" && item !== "UNKNOWN"),
            slice_thickness: {
                min: 0,
                max: 10
            }
        },
        pagination: {
            pageSize: PAGE_SIZE,
            offset: 0
        },
    },
    setSearchRequestPageOffset: (searchRequestPageOffset: number) => set((state) => {
        return {
            searchRequestPageOffset,
            searchRequest: {
                ...state.searchRequest,
                pagination: {
                    ...state.searchRequest.pagination,
                    offset: searchRequestPageOffset
                }
            }
        }
    }),
    selectedPageSize : PAGE_SIZE,
    setSelectedPageSize: (selectedPageSize: number) => set((state) => {
        return {
            selectedPageSize,
            searchRequest: {
                ...state.searchRequest,
                pagination: {
                    ...state.searchRequest.pagination,
                    pageSize: selectedPageSize
                }
            }
        }
    }),
    updateSearchQuery: (searchQuery) => set(state => {
        return {
            searchRequest: {
                ...state.searchRequest,
                search_query: searchQuery,
            }
        }
    }),
    updateSearchRequestFilters: (filters: SearchFilters) => set(state => {
        return {
            searchRequest: {
                ...state.searchRequest,
                filters: filters
            }
        }
    }),
    updateSortBy: (sort: any) => set(state => {
        let newSearchRequest:any
        newSearchRequest ={
            ...state.searchRequest
        }
        if(sort.length > 0){
            newSearchRequest.sort = sort
        } else {
            delete newSearchRequest.sort
        }
        return {
            
            searchRequest: newSearchRequest
        }
    }),
    updateSearchSummary: (searchSummary: boolean) => set(state => {
        return {
            searchRequest: {
                ...state.searchRequest,
                search_summary: searchSummary
            }
        }
    }),
    setStudies: (studies: StudiesByPageObject) => set({ studies }),
    setActiveStudies: (activeStudies: Study[]) => set({ activeStudies }),
    setShouldFetchStudies: (shouldFetchStudies: boolean) => set({ shouldFetchStudies }),
    setIsRetrievingStudies: (isRetrievingStudies: boolean) => set({ isRetrievingStudies }),
    setNoResultsFound: (noResultsFound: boolean) => set({ noResultsFound }),
    setNumberOfStudies: (numberOfStudies: number | null) => set({ numberOfStudies }),
    setDisableSearch: (disableSearch: boolean) => set({ disableSearch }),
    setShouldFetchNumberOfStudies: (shouldFetchNumberOfStudies: boolean) => set({ shouldFetchNumberOfStudies }),
    setIsFetchingNumberOfStudies: (isFetchingNumberOfStudies: boolean) => set({ isFetchingNumberOfStudies }),
    setdataStoreSearchParams: (dataStoreSearchParams: SearchRequest) => set((state) => {
        return {
            dataStoreSearchParams: {
                ...dataStoreSearchParams
            }
        }
    }),
    clearButtonClicked: false,
    setClearButtonClicked: (clearButtonClicked:boolean)=> set({clearButtonClicked}),
    studiesPagination: 0,
    setStudiesPagination: (studiesPagination:number)=> set({studiesPagination}),
}))