import { useState, useEffect } from "react";
import RangeSlider from '../../common/RangeSlider';
import { useFilterStore } from '../../../store/filterStore';
import { usePatientStore } from '../../../store/patientStore'
import { alldropdowns } from "../dropdownList";
import { useFilterDropDownStore } from "../../../store/filterDropDownStore";

type SliceThicknessFilterProps = {
    id?: number;
}

export default function SliceThicknessFilter({id}: SliceThicknessFilterProps) {
  const sliceThicknessSelections = useFilterStore(state => state.sliceThicknessSelections);
  const setSliceThicknessSelections = useFilterStore(state => state.setSliceThicknessSelections);
  const setPatientSliceThicknessSelections = usePatientStore(state => state.setSliceThicknessSelections);
  const patientFilters = usePatientStore(state => state.filters);
  const setFilterDropdowns = useFilterDropDownStore((state) => state.setFilterDropdowns);
  const sliceThicknessRange = [0, 10];

  useEffect(()=>{
    setFilterDropdowns(alldropdowns);
  },[sliceThicknessSelections]);
  return (
    <>
    <div className='flex flex-row justify-between py-4 z-0'>
        <p className='text-base'>Slice Thickness</p>
        <div className="w-3/5">
            <RangeSlider 
              className='w-40 h-8' 
              defaultValue={sliceThicknessRange} 
              value={id != null ? [patientFilters[id].filters.slice_thickness.min, patientFilters[id].filters.slice_thickness.max ] : sliceThicknessSelections}
              max={10} 
              step={0.1}
              updateFilter={(values: number[]) => {
                id != null ? setPatientSliceThicknessSelections(values, id) : setSliceThicknessSelections(values)
              }}
            />
        </div>
    </div>
    </>
  );
};

