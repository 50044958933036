import React from "react";
import { useChartsStore } from '../../../../../store/chartsStore';
import PieChart from "./common/PieChart";
import { addOtherValueToChartData } from "../../../../../utils/data_formatting";

export default function BodyPartChart() {
  // Charts Store
  const cohortsChartsData = useChartsStore((state) => state.cohortsChartsData);
  const { body_part_examined: bodyPartData } = cohortsChartsData;

  const sortedEntries = Object.entries(bodyPartData || {}).sort(([, valueA], [, valueB]) => Number(valueB) - Number(valueA));
  const otherIndex = sortedEntries.findIndex(([key]) => key === "Other");
  if (otherIndex !== -1 && otherIndex < 10) {
    sortedEntries.push(sortedEntries.splice(otherIndex, 1)[0]);
  }

  const filteredBodyPartData = sortedEntries.slice(0, 10).reduce((obj, [key, value]) => {
    obj[key] = value as number;
    return obj;
  }, {} as { [key: string]: number });

  addOtherValueToChartData(sortedEntries as [string, number][], filteredBodyPartData);

  return (
    <PieChart
      data={filteredBodyPartData}
      colors={["#A78BFA", "#F472B6", "#10B981", "#6366F1", "#F59E0B", "#E11D48", "#84CC16", "#3B82F6", "#EC4899", "#F97316", "#0000FF"]}
      chartId="body-part-pie-chart"
    />
  );
}
