import { useState, useEffect } from "react";
import { useFilterStore } from "../../../store/filterStore";
import { usePatientStore } from '../../../store/patientStore'
import { useSearchTypeStore } from "../../../store/searchTypeStore";
import { alldropdowns } from "../dropdownList";
import { useFilterDropDownStore } from "../../../store/filterDropDownStore";

const sexOptions = [
    { value: 'M', label: 'Male', checked: true },
    { value: 'F', label: "Female", checked: true },
    { value: 'O', label: "Other", checked: true },
]


export default function SexFilter() {
    const sexSelections = useFilterStore(state => state.sexSelections);
    const setSexSelections = useFilterStore(state => state.setSexSelections);
    const setPatientSexSelections = usePatientStore(state => state.setSexSelections);
    const patientFilters = usePatientStore(state => state.filters);
    const [ sexOptionState, setSexOptionState ] = useState(sexOptions);
    const searchType = useSearchTypeStore(state => state.searchType);
    const setFilterDropdowns = useFilterDropDownStore((state) => state.setFilterDropdowns);
    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const value = e.target.value;

        // Update sex option state to reflect the change
        const newSexOptionState = [...sexOptionState];
        newSexOptionState[index].checked = newSexOptionState[index].checked ? false : true;
        setSexOptionState(newSexOptionState);
        const selected = sexOptionState
        .filter((item: any) => item.value !== value && item.checked)
        .map((item: any) => item.value);
        let selectedSex = [];
        if(e.target.checked) {
            selectedSex = [...selected, value];
        } else {
            selectedSex = selected;
        }
        selectedSex.find((value)=>value === 'O') && selectedSex.push("U");
        searchType === "patient" ? setPatientSexSelections(selectedSex) : setSexSelections(selectedSex);
        setFilterDropdowns(alldropdowns);
    }

    useEffect(() => {
        setSexOptionState(sexOptions.map((sexOption) => {
            sexOption.checked = searchType ==="study" ? sexSelections.includes(sexOption.value) :patientFilters[0].filters.sex.includes(sexOption.value);
            return sexOption;
        }));
    }, [sexSelections, patientFilters, searchType])

    return (
        <div className="flex flex-row justify-between py-4">
            <p className="text-base">Sex</p>
            <div className="flex flex-row gap-x-4">
                {sexOptionState.map((option, index) => (
                    <div key={index} className="flex flex-row">
                        <input
                            className="mr-2 mt-[3px]"
                            type="checkbox"
                            value={option.value}
                            checked={option.checked}
                            onChange={(e) => handleCheckboxChange(e, index)}
                        />
                        <label>{option.label}</label>
                    </div>
                ))}
            </div>
        </div>
    )
}